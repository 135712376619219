import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isWebp: false,
    loadImages: false,
    activePage: null,
  },
  reducers: {
    setWebp: (state, action) => {
      state.isWebp = action.payload;
      state.loadImages = true;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
  },
});

export const { setWebp, setActivePage } = uiSlice.actions;

export const selectWebp = (state) => state.ui.isWebp;
export const selectLoad = (state) => state.ui.loadImages;
export const selectActivePage = (state) => state.ui.activePage;

export default uiSlice.reducer;
