import { createSlice } from '@reduxjs/toolkit';

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    showCheckout: false,
    defaultPlanId: '',
    landingType: 'fullPrice',
    plan: '',
    stripeObj: null,
    prices: {
      monthlyPrice: null,
      paymentPrice: null,
    },
    landingAnimation: false,
  },
  reducers: {
    setDefaultPlanId: (state, action) => {
      state.defaultPlanId = action.payload;
    },
    setShowCheckout: (state, action) => {
      state.showCheckout = action.payload;
    },
    setLandingType: (state, action) => {
      state.landingType = action.payload || 'fullPrice';
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setStripeObj: (state, action) => {
      state.stripeObj = action.payload;
    },
    setPlanPrices: (state, action) => {
      state.prices = action.payload;
    },
    setLandingAnimation: (state, action) => {
      state.landingAnimation = action.payload;
    },
  },
});

export const {
  setShowCheckout,
  setDefaultPlanId,
  setPlan,
  setLandingType,
  setStripeObj,
  setPlanPrices,
  setLandingAnimation,
} = plansSlice.actions;

export const selectShowCheckout = (state) => state.plans.showCheckout;
export const selectDefaultPlanId = (state) => state.plans.defaultPlanId;
export const selectPlan = (state) => state.plans.plan;
export const selectLandingType = (state) => state.plans.landingType;
export const selectStripeObj = (state) => state.plans.stripeObj;
export const selectPlanPrices = (state) => state.plans.prices;
export const selectLandingAnimation = (state) => state.plans.landingAnimation;

export default plansSlice.reducer;
