import { createGlobalStyle } from 'styled-components';
import { fontFaces } from '../utils/fontFaces';

const GlobalStyle = createGlobalStyle`

${fontFaces
  .map(
    (face) => `
    @font-face {
      font-family: '${face.family}';
      ${face.weights
        .map(
          (weight) =>
            `src: ${weight.src} format('${weight.format}') ${weight.weight};`
        )
        .join('\n      ')}
      font-style: ${face.options.style};
      font-display: ${face.options.display};
    }
    ${face.weights.map(
      (weight) => `
        .${face.family}-${weight.weight} {
          font-family: '${face.family}';
          font-weight: ${weight.weight};
        }
      `
    )}
  `
  )
  .join('\n')}


  * {
      box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      font-family: 'SF Pro Display', 'SF Pro Text', sans-serif;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      > div {
        width: 100%;
      }
  }
  p {
    margin: 0;
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="textarea"],
  input[type="radio"],
  textarea,
  input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  }
  input, div, span, button, li, .StripeElement {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
`;

export default GlobalStyle;
