import {
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from 'react-router-dom';
import WelcomeScreen, { MainWrapper } from './pages/welcome/WelcomeScreen';
import EmailScreen from './pages/signup/EmailScreen';
import RemarketingAuth from './pages/welcome/RemarketingAuth';
import CrashDetection from './pages/options/CrashDetection';
import SpeedControl from './pages/options/SpeedControl';
import FallDetection from './pages/options/FallDetection';
import PlaceAlerts from './pages/options/PlaceAlerts';
import LocationHistory from './pages/options/LocationHistory';
import SosButton from './pages/options/SosButton';
import RealtimeLocation from './pages/options/RealtimeLocation';
import PairWearables from './pages/options/PairWearables';
import CheckIns from './pages/options/CheckIns';
import PlatformSupport from './pages/options/PlatformSupport';
import ArSearch from './pages/options/ArSearch';
import PrivateChat from './pages/options/PrivateChat';
import LowBatteryNotification from './pages/options/LowBatteryNotification';
import TargetTrack from './pages/track/TargetTrack';
import PlaceTrack from './pages/track/PlaceTrack';
import AddPlace from './pages/track/AddPlace';
import ProfileScreen from './pages/profile/ProfileScreen';
import ErrorScreen from './pages/error/ErrorScreen';
import SuccessScreen from './pages/success/SuccessScreen';
import { useCallback, useEffect } from 'react';
import { setActivePage } from './store/ui';
import SegmentedProgressBar from './components/AppProgressBar';
import PlanCheckout from './PlanCheckout/PlanCheckout';
import landingTypesList from './utils/landingTypes';

const RouteWithMainWrapper = ({ element: Element }) => (
  <MainWrapper>
    <SegmentedProgressBar />
    <Element />
  </MainWrapper>
);

const routes = [
  ...landingTypesList.map((type) => ({
    path: type,
    element: <RouteWithMainWrapper element={WelcomeScreen} />,
  })),
  {
    path: '/crash-detection',
    element: <RouteWithMainWrapper element={CrashDetection} />,
  },
  {
    path: '/speed-control',
    element: <RouteWithMainWrapper element={SpeedControl} />,
  },
  {
    path: '/fall-detection',
    element: <RouteWithMainWrapper element={FallDetection} />,
  },
  {
    path: '/place-alerts',
    element: <RouteWithMainWrapper element={PlaceAlerts} />,
  },
  {
    path: '/location-history',
    element: <RouteWithMainWrapper element={LocationHistory} />,
  },
  {
    path: '/sos-button',
    element: <RouteWithMainWrapper element={SosButton} />,
  },
  {
    path: '/real-time-location',
    element: <RouteWithMainWrapper element={RealtimeLocation} />,
  },
  {
    path: '/pair-wearables',
    element: <RouteWithMainWrapper element={PairWearables} />,
  },
  {
    path: '/check-ins',
    element: <RouteWithMainWrapper element={CheckIns} />,
  },
  {
    path: '/platform-support',
    element: <RouteWithMainWrapper element={PlatformSupport} />,
  },
  {
    path: '/ar-search',
    element: <RouteWithMainWrapper element={ArSearch} />,
  },
  {
    path: '/private-chat',
    element: <RouteWithMainWrapper element={PrivateChat} />,
  },
  {
    path: '/low-battery-notification',
    element: <RouteWithMainWrapper element={LowBatteryNotification} />,
  },
  {
    path: '/choose-target',
    element: <RouteWithMainWrapper element={TargetTrack} />,
  },
  {
    path: '/choose-place',
    element: <RouteWithMainWrapper element={PlaceTrack} />,
  },
  {
    path: '/add-place',
    element: <AddPlace />,
  },
  {
    path: '/signup',
    element: <EmailScreen />,
  },
  {
    path: '/creating-profile',
    element: <ProfileScreen />,
  },
  {
    path: '/checkout',
    element: <PlanCheckout />,
  },
  {
    path: '/checkout/error',
    element: <ErrorScreen />,
  },
  {
    path: '/checkout/success',
    element: <SuccessScreen />,
  },

  {
    path: '/new',
    element: <RemarketingAuth />,
  },
  {
    path: '/new-discount',
    element: <RemarketingAuth />,
  },
  {
    path: '/freetrial-monthly',
    element: <RemarketingAuth />,
  },
  {
    path: '/freetrial-weekly',
    element: <RemarketingAuth />,
  },
  {
    path: '/month-trial',
    element: <RemarketingAuth />,
  },
  {
    path: '/fdtrial',
    element: <RemarketingAuth />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const Router = ({ dispatch }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const setPath = useCallback(
    (path) => {
      dispatch(setActivePage(path.replace('/', '')));
    },
    [dispatch]
  );

  useEffect(() => {
    setPath(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname, dispatch, setPath]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(location.pathname);
    };
    const {
      location: { pathname, search },
    } = window;
    const path = `${pathname}${
      landingTypesList.includes(pathname) ? search : ''
    }`;

    window.history.pushState(null, '', path);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate, location.pathname]);

  const wrappedRoutes = routes.map((route) => {
    if (route.wrapInAppContainer) {
      return {
        ...route,
        element: <RouteWithMainWrapper element={route.element} />,
      };
    }
    return route;
  });
  return useRoutes(wrappedRoutes);
};

export default Router;
