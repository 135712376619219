import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  isAndroid,
  isDesktop,
  isIOS,
  osName,
  osVersion,
} from 'react-device-detect';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import AppSubtitle from '../../components/AppSubtitle';
import { selectEventsData, setEventDataList } from '../../store/events';
import { selectLoad, selectWebp } from '../../store/ui';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import { useLandingType, queryParser } from 'onboarding-initialization';
import landingTypesList from '../../utils/landingTypes';
import { setLandingType, setLandingAnimation } from '../../store/plans';
import useImagesSrc from '../../hooks/useImagesSrc';
import useWindowWidth from '../../hooks/useWindowWidth';
import { usePreloadImages } from '../../hooks/useImagePreloader';
import { setUuid } from '../../store/signup';

const WelcomeTitle = styled.p`
  margin: 0 0 4px 0;
  font-size: ${(props) => props.theme.fontSizes.font_24};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  text-align: center;
  letter-spacing: -0.005em;
  @media screen and (min-width: 450px) {
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  }
`;

const AppLogo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.font_48};
  font-weight: ${(props) => props.theme.fontWeights.extraBold};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_48};
  background: ${(props) => props.theme.colors.gradient_title};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
  letter-spacing: -0.005em;
  @media screen and (min-width: 450px) {
    font-size: ${(props) => props.theme.fontSizes.font_36};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_36};
  }
`;

const WelcomeStub = styled.div`
  min-height: 6px;
  width: 100%;
  position: absolute;
  top: 20px;
  display: none;

  @media screen and (min-width: 450px) {
    display: flex;
    position: static;
    margin-bottom: 10vh;
  }
`;

export const MainWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  @media screen and (min-width: 450px) {
    justify-content: center;
    padding-bottom: 20px;
  }
`;

const WelcomeImage = styled.div`
  background-image: ${({ imgSource }) => imgSource && `url('${imgSource}')`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  width: 100vw;
  max-height: 300px;
  height: 100%;
  @media screen and (min-width: 376px) {
    background-image: ${({ imgSource }) => imgSource && `url('${imgSource}')`};
    max-height: 512px;
  }
  @media screen and (min-width: 450px) {
    background-size: contain;
    background-position: center;
    min-height: 150px;
  }
`;

const WelcomeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding-top: 0;
  padding-bottom: 34px;
  max-width: 200px;
  min-height: 150px;
  min-width: 330px;
  @media screen and (min-width: 450px) {
    margin-bottom: 20px;
  }
`;

const WelcomeScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [eventParams, setEventParams] = useState(null);
  const [currentLandingType, setCurrentLandingType] = useState('fullPrice');
  const eventsData = useSelector(selectEventsData);
  const load = useSelector(selectLoad);
  const navigate = useNavigate();
  const isWebp = useSelector(selectWebp);
  const location = useLocation();
  const { imagesSources } = useImagesSrc();
  const windowWidth = useWindowWidth();
  const allowedLandingAnimation = useMemo(
    () => ['anim', 'discount-anim', 'trial-anim', 'freetrial-anim'],
    []
  );

  const landingParam = location.pathname.replace(/\//g, '');
  const { landingType, paywallType } = useLandingType(
    landingParam,
    landingTypesList
  );

  usePreloadImages(imagesSources.crashDetection);

  useEffect(() => {
    if (eventParams || !paywallType) return;
    const { fbclid, gclid, ...utms } = queryParser(location.search);
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      AdSetName,
      AdName,
      CampaignID,
      AdSetID,
      AdID,
    } = utms;
    const facebookUtm = fbclid ? 'facebook' : null;
    const appDomain = `${window.location.origin}${
      landingParam.length ? `/${landingParam}` : ''
    }`;
    const utmSource = utms.utm_source || facebookUtm || 'organic';
    utms.utm_source = utmSource;
    dispatch(
      setLandingAnimation(allowedLandingAnimation.includes(paywallType))
    );
    dispatch(setLandingType(paywallType));
    setCurrentLandingType(paywallType);
    const localEventsData = {
      landing_type: `app_${landingType}`,
      app_domain: appDomain,
      app_name: 'GeoZilla',
      landingType,
      appDomain,
      appName: 'GeoZilla',
      fbclid,
      gclid,
      utm: utms,
      utm_source: utmSource,
      os: `${osVersion} ${osName}`,
    };

    const userProperties = {
      AppDomain: appDomain,
      OSDevice: `${
        isDesktop ? 'desktop' : isIOS ? 'iOS' : isAndroid ? 'Android' : osName
      }`,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      AdSetName,
      AdName,
      CampaignID,
      AdSetID,
      AdID,
    };

    dispatch(setEventDataList(localEventsData));
    setAmplitudeUserProperties(userProperties);
    setEventParams(localEventsData);
  }, [
    dispatch,
    eventParams,
    location,
    allowedLandingAnimation,
    landingParam,
    landingType,
    paywallType,
  ]);

  useEffect(() => {
    if (!eventParams) return;
    sendAnalyticsEvents(EVENTS.onboardingStarted, eventParams);
  }, [eventParams]);

  useEffect(() => {
    dispatch(setUuid());
  }, [dispatch]);

  const onContinueClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingStartedSubmitted, eventsData);
    navigate('/crash-detection');
  };

  const imgSource = allowedLandingAnimation.includes(currentLandingType)
    ? '../assets/desktop/anim/Welcome.gif'
    : windowWidth >= 375 && isWebp
    ? '../assets/desktop/webp/Welcome.webp'
    : windowWidth >= 375 && !isWebp
    ? '../assets/desktop/png/Welcome.png'
    : windowWidth < 375 && isWebp
    ? '../assets/mobile/webp/Welcome.webp'
    : '../assets/mobile/png/Welcome.png';

  if (load) {
    return (
      <>
        <>
          <WelcomeStub />
          <WelcomeImage imgSource={imgSource} />
          <WelcomeContent>
            <WelcomeTitle>{t('welcome_to')}</WelcomeTitle>
            <AppLogo>GeoZilla</AppLogo>
            <AppSubtitle
              marginContainer="0px"
              subtitle={t('welcome_sub')}
              containerWidth="310px"
            />
          </WelcomeContent>
        </>
        <AppButton
          customStickyWidth="100%"
          onClick={onContinueClick}
          bottomPosition="24px"
          customBeforeBg="none"
        >
          {t('continue')}
        </AppButton>
      </>
    );
  }
  return <></>;
};

export default WelcomeScreen;
