import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppContainer from '../../components/AppContainer';
import AppTitle from '../../components/AppTitle';
import AppSubtitle from '../../components/AppSubtitle';
import { EVENTS } from '../../analytics/events';
import {
  selectEmail,
  selectSuccess,
  selectUserUuid,
  selectValidity,
  sendAPIRequest,
} from '../../store/signup';
import { selectLandingType } from '../../store/plans';
import { selectEventsData } from '../../store/events';
import { sendAnalyticsEvents } from '../../analytics/common';
import AppButton from '../../components/AppButton';
import useWindowWidth from '../../hooks/useWindowWidth';
import EmailInput from './EmailInput';
import { useStickyButtonStyles } from '../../hooks/useStickyButtonStyles';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';
const isStage = process.env.REACT_APP_ENV === 'stage';

const AppEmailInfo = styled.p`
  max-width: 327px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
  color: ${(props) => props.theme.colors.info};
  font-family: 'SF Pro Text';
  text-align: center;
  margin-bottom: 0;

  @media screen and (min-width: 450px) {
    font-size: ${(props) => props.theme.fontSizes.font_14};
    //max-width: 350px;
    text-align: ${textAlign};
    margin-bottom: 24px;
  }
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    align-self: ${isArabic ? 'end' : 'start'};
  }
`;

const EmailScreen = () => {
  const isSuccess = useSelector(selectSuccess);
  const eventsData = useSelector(selectEventsData);
  const email = useSelector(selectEmail);
  const isValid = useSelector(selectValidity);
  const [initEventSent, setInitEventStatus] = useState(false);
  const { stickyButtonStyles } = useStickyButtonStyles();
  const landingType = useSelector(selectLandingType);
  const userUuid = useSelector(selectUserUuid);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const forceUseBT = !isStage && eventsData.utm_source === 'organic'; // remove after verification

  useEffect(() => {
    if (initEventSent) return;

    sendAnalyticsEvents(EVENTS.onboardingEnterEmailShown, eventsData);
    setInitEventStatus(true);
  }, [initEventSent, eventsData]);

  useEffect(() => {
    if (isSuccess) {
      sendAnalyticsEvents(EVENTS.signupSuccess, eventsData);
      navigate('/creating-profile');
    }
  }, [isSuccess, navigate, eventsData, email]);

  const onBtnClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingEnterEmailSubmitted, eventsData);
    dispatch(sendAPIRequest({ email, landingType, userUuid, forceUseBT }));
  };

  const btn = (
    <AppButton
      alignStickyBtn={'flex-start'}
      customStickyWidth={windowWidth >= 450 ? '100%' : '90%'}
      customBeforeBg={'none'}
      onClick={onBtnClick}
      disabled={!isValid}
      stickyProps={stickyButtonStyles}
      bottomPosition={`${24}px`}
    >
      {t('continue')}
    </AppButton>
  );
  return (
    <AppContainer
      customPadding={
        windowWidth >= 450
          ? '19px 24px 24px'
          : windowWidth <= 380
          ? '10px 24px 24px'
          : '30px 24px 20px'
      }
    >
      <EmailWrapper>
        <AppTitle
          textAlign={textAlign}
          title={t('whats_your_email')}
          containerWidth={'330px'}
          marginContainer={'10px 0px 12px 0px'}
        />
        <AppSubtitle
          textAlign={textAlign}
          subtitle={t('email_description')}
          containerWidth={'313px'}
          marginContainer={
            windowWidth >= 450 ? '0px 0px 16px 0px' : '0px 0px 8px 0px'
          }
        />
        <EmailInput />
        <AppEmailInfo>{t('email_information')}</AppEmailInfo>
        {windowWidth >= 450 && btn}
      </EmailWrapper>
      {windowWidth < 450 && btn}
    </AppContainer>
  );
};

export default EmailScreen;
