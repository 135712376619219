import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TimerTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_20};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.title};
  letter-spacing: -0.2px;
  margin-bottom: 8px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TimeItem = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraBold};
  font-size: ${(props) => props.theme.fontSizes.font_20};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.btn};
  padding: 6px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.gradient_btn};
  position: relative;

  span {
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-size: ${(props) => props.theme.fontSizes.font_12};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_14};
  }

  &:not(:last-child) {
    margin-right: 14px;
    &:after {
      content: ':';
      display: block;
      font-weight: ${(props) => props.theme.fontWeights.light};
      font-size: ${(props) => props.theme.fontSizes.font_20};
      line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
      position: absolute;
      top: 5px;
      right: -10px;
    }
  }
`;

const checkNumber = (value) => {
  value = value.toString();
  if (value.length === 1) {
    return `0${value}`;
  }
  return value;
};

const timer = () => {
  const dateNow = new Date();
  let day = dateNow.getDate() % 3;
  const hour = 23 - dateNow.getHours();
  const minutes = 59 - dateNow.getMinutes();
  const second = 59 - dateNow.getSeconds();
  if (day === 1) {
    day += 2;
  }

  return {
    day: checkNumber(day),
    hour: checkNumber(hour),
    minutes: checkNumber(minutes),
    second: checkNumber(second),
  };
};

const AppTimer = () => {
  const { t } = useTranslation();

  const [date, setDate] = useState({
    day: '00',
    hour: '00',
    minutes: '00',
    second: '00',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = timer();
      setDate({
        ...date,
        ...newDate,
      });
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <TimerContainer>
      <TimerTitle>{t('limited_offer')}:</TimerTitle>
      <TimeWrapper>
        <TimeItem>
          {date.day}
          <span>{t('days')}</span>
        </TimeItem>
        <TimeItem>
          {date.hour}
          <span>{t('hours')}</span>
        </TimeItem>
        <TimeItem>
          {date.minutes}
          <span>{t('minutes')}</span>
        </TimeItem>
        <TimeItem>
          {date.second}
          <span>{t('seconds')}</span>
        </TimeItem>
      </TimeWrapper>
    </TimerContainer>
  );
};

export default AppTimer;
