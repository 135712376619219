export const fontFaces = [
  {
    family: 'SF Pro Display',
    weights: [
      {
        weight: 800,
        src: `
            url("../fonts/SF_Pro_Display/SFProDisplay-Heavy.ttf") format('truetype'),
            url("../fonts/SF_Pro_Text/SFProDisplay-Heavy.woff") format('woff'),
            url("../fonts/SF_Pro_Text/SFProDisplay-Heavy.woff2") format('woff2')
        `,
        options: {
          weight: '800',
          display: 'swap',
        },
      },
      {
        weight: 700,
        src: `url("../fonts/SF_Pro_Display/SFProDisplay-Bold.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Bold.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Bold.woff2") format('woff2')
        `,
        options: {
          weight: '700',
          display: 'swap',
        },
      },
      {
        weight: 600,
        src: `url("../fonts/SF_Pro_Display/SFProDisplay-Semibold.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Semibold.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Semibold.woff2") format('woff2')
        `,
        options: {
          weight: '600',
          display: 'swap',
        },
      },
      {
        weight: 500,
        src: `url("../fonts/SF_Pro_Display/SFProDisplay-Medium.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Medium.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Medium.woff2") format('woff2')
        `,
        options: {
          weight: '500',
          display: 'swap',
        },
      },
      {
        weight: 400,
        src: `url("../fonts/SF_Pro_Display/SFProDisplay-Regular.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Regular.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProDisplay-Regular.woff2") format('woff2')
        `,
        options: {
          weight: '400',
          display: 'swap',
        },
      },
    ],
    options: {
      display: 'swap',
      style: 'normal',
    },
  },
  {
    family: 'SF Pro Text',
    weights: [
      {
        weight: 800,
        src: `url("../fonts/SF_Pro_Text/SFProText-Heavy.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProText-Heavy.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProText-Heavy.woff2") format('woff2')
        `,
        options: {
          weight: '800',
          display: 'swap',
        },
      },
      {
        weight: 700,
        src: `url("../fonts/SF_Pro_Text/SFProText-Bold.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProText-Bold.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProText-Bold.woff2") format('woff2')
        `,
        options: {
          weight: '700',
          display: 'swap',
        },
      },
      {
        weight: 600,
        src: `url("../fonts/SF_Pro_Text/SFProText-Semibold.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProText-Semibold.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProText-Semibold.woff2") format('woff2')
        `,
        options: {
          weight: '600',
          display: 'swap',
        },
      },
      {
        weight: 500,
        src: `url("../fonts/SF_Pro_Text/SFProText-Medium.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProText-Medium.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProText-Medium.woff2") format('woff2')
        `,
        options: {
          weight: '500',
          display: 'swap',
        },
      },
      {
        weight: 400,
        src: `url("../fonts/SF_Pro_Text/SFProText-Regular.ttf") format('truetype'),
              url("../fonts/SF_Pro_Text/SFProText-Regular.woff") format('woff'),
              url("../fonts/SF_Pro_Text/SFProText-Regular.woff2") format('woff2')
        `,
        options: {
          weight: '400',
          display: 'swap',
        },
      },
    ],
    options: {
      display: 'swap',
      style: 'normal',
    },
  },
];
