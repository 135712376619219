import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import useDebounce from '../../hooks/useDebounce';
import { selectEventsData } from '../../store/events';
import { selectPlace } from '../../store/info';
import AppContainer from '../../components/AppContainer';
import googleSrc from '../../scripts/googlePlaces';
import useSrcScript from '../../hooks/useSrcScripts';
import AppModal from '../../components/AppModal';
import PlaceAddedPopup from './PlaceAddedPopup';
import useWindowWidth from '../../hooks/useWindowWidth';
import { ProfileImagesLinks } from '../../utils/utils';
import { usePreloadImages } from '../../hooks/useImagePreloader';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';
const { REACT_APP_LOCATIONIQ_TOKEN } = process.env;

const FieldsWrapper = styled.div`
  width: 100%;
  max-width: 330px;
  padding: 16px 0;
`;

const FakeInput = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  align-items: center;
  justify-content: flex-start;
  height: 62px;
  background: ${({ theme }) => theme.colors.main};
  border: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
  border-radius: 8px;
  padding: 0 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
    height: 45px;
  }

  span {
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font_14};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
    color: ${(props) => props.theme.colors.title};
    font-family: 'SF Pro Text';
    padding-${textAlign}: 12px;
    text-align: ${textAlign};
  }
  :first-child {
    &::before {
      content: url('../assets/map_track/star.svg');
      width: 15px;
      height: 15px;
    }
  }
  :nth-child(2) {
    margin-bottom: 25px;
    &::before {
      content: url('../assets/map_track/pin.svg');
      width: 15px;
      height: 19px;
    }
    @media screen and (max-width: 450px) {
      margin-bottom: 14px;
    }
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 650px;
  max-height: 400px;
  min-height: 200px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: ${({ theme }) => `1px solid ${theme.colors.btn}`};
  border-radius: 38px;
  margin-bottom: 24px;
  @media screen and (max-width: 450px) {
    max-width: 80vw;
    max-height: 40vh;
  }

  div#map {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
  }
`;

const MapScale = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  max-width: 330px;
  padding: 2px;
  border: ${({ theme }) => `1px solid ${theme.colors.border_scale}`};
  background: ${({ theme }) => theme.colors.main};
`;

const ScaleItem = styled.div`
  width: 67px;
  height: 100%;
  border-radius: 7px;
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${({ active, theme }) =>
    active ? theme.fontSizes.font_14 : theme.fontSizes.font_13};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_20};
  font-family: 'SF Pro Text';
  color: ${({ active, theme }) =>
    active ? theme.colors.main : theme.colors.subtitle};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  ${({ active, theme }) =>
    active &&
    `background: ${theme.colors.btn};
     border: 0.5px solid ${theme.colors.border_darker};
     border-radius: 7px;
     `}

  &:not(:last-child) {
    ${({ active, nextToActive, theme }) =>
      !(active || nextToActive) &&
      `
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -8px;
            width: 1px;
            height: 16px;
            background: ${theme.colors.vertical_line};
            border-radius: 0.5px;
        }
        `}
  }
`;

const Marker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.selected_bg};
  pointer-events: none;
  z-index: 2;

  @media screen and (max-width: 450px) {
    width: 115x;
    height: 115x;
  }

  &:before {
    content: url('../assets/map_track/map_pin.svg');
    display: block;
    width: 21px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const LocationButton = styled.div`
  position: absolute;
  bottom: 21px;
  right: 21px;
  left: 21px;
  ${textAlign}: auto;
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  background: ${({ theme }) => theme.colors.btn};
  border-radius: 14px;
  cursor: pointer;
`;
const SkipBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${`flex-${isArabic ? 'start' : 'end'}`};
  max-width: 330px;
`;

const SkipButton = styled.button`
  margin-top: 25px;
  display: block;
  border: none;
  background: transparent;
  outline: none;
  font-family: 'SF Pro Text';
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
  color: ${({ theme, mobile }) =>
    mobile ? theme.colors.title : theme.colors.skip_btn};
  padding: 0;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 450px) {
    margin-top: 0px;
  }
`;

const LocationIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url('../assets/map_track/location.svg');
`;

const AddPlace = () => {
  const status = useSrcScript(googleSrc);
  const place = useSelector(selectPlace);
  const [address, setAddress] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [activeScale, setActiveScale] = useState(isArabic ? 4 : 0);
  const [coordinates, setCoordinates] = useState({
    lat: -33.867,
    lng: 151.195,
  });
  const debouncedCoordinates = useDebounce(coordinates, 800);
  const [map, setMap] = useState(null);
  const eventsData = useSelector(selectEventsData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const [countryCode, setCountryCode] = useState('eu');
  usePreloadImages(ProfileImagesLinks);
  useEffect(() => {
    getCurrentPosition();
  }, []);

  useEffect(() => {
    debouncedCoordinates &&
      getAddress(debouncedCoordinates).then((address) => setAddress(address));
  }, [debouncedCoordinates]);

  useEffect(() => {
    map && map.setCenter(coordinates);
  }, [coordinates, map]);

  useEffect(() => {
    if (status === 'ready') {
      const lat = -33.867;
      const lng = 151.195;
      const initPlace = new window.google.maps.LatLng(lat, lng);

      const mapObj = new window.google.maps.Map(
        document.getElementById('map'),
        {
          center: initPlace,
          zoom: 18,
          disableDefaultUI: true,
          clickableIcons: false,
        }
      );
      setMap(mapObj);
    }
  }, [status]);

  useEffect(() => {
    if (!map) return;
    map.addListener('dragend', () => {
      const { lat, lng } = map.getCenter();
      const coords = { lat: lat(), lng: lng() };
      setCoordinates((prevState) => {
        if (prevState !== coords) return coords;
      });
    });
  }, [map]);

  useEffect(
    () => sendAnalyticsEvents(EVENTS.onboardingAddPlace, eventsData),
    [eventsData]
  );

  const getCurrentPosition = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      setCoordinates((prevState) => {
        if (prevState !== coords) return coords;
      });
    });
  };

  const getAddress = async (coords) => {
    const { lat, lng } = coords;
    const url = `https://eu1.locationiq.com/v1/reverse.php?key=${REACT_APP_LOCATIONIQ_TOKEN}&lat=${lat}&lon=${lng}&format=json`;
    const response = await fetch(url, { method: 'GET' });
    const data = await response.json();
    setCountryCode(data.address.country_code);
    return data.address.road
      ? `${data.address.road}, ${
          data.address.house_number || data.address.neighbourhood || ''
        }`
      : data.address.suburb || data.address.state;
  };

  const onAddPlaceClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingAddPlaceSubmitted, eventsData);
    setShowAddPopup(true);
  };

  const onSkipClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingAddPlaceSkip, eventsData);
    navigate('/signup');
  };

  const SCALES =
    countryCode === 'us' ? [500, 1500, 3000, 1, 3] : [150, 500, 1, 2, 5];

  const renderScales = (sc, i) => (
    <ScaleItem
      key={sc}
      active={i === activeScale}
      nextToActive={activeScale - i === 1}
      onClick={() => setZoom(i)}
    >
      {sc}
      {countryCode === 'us'
        ? sc < 10
          ? t('m')
          : t('ft')
        : sc < 10
        ? t('km')
        : t('m')}
    </ScaleItem>
  );

  const setZoom = (i) => {
    const z = isArabic ? 4 - i : i;
    map.setZoom(18 - z);
    setActiveScale(i);
  };

  return (
    <AppContainer>
      {windowWidth < 450 && (
        <SkipBtnWrapper>
          <SkipButton onClick={onSkipClick} id="skip-map-button" mobile>
            {t('skip')}
          </SkipButton>
        </SkipBtnWrapper>
      )}
      <FieldsWrapper>
        <FakeInput id="add-home-input">
          <span>{t(place) || t('home')}</span>
        </FakeInput>
        <FakeInput id="add-location-input">
          <span>{address || t('define_location')}</span>
        </FakeInput>
        <MapScale>
          {(isArabic ? SCALES.reverse() : SCALES).map(renderScales)}
        </MapScale>
      </FieldsWrapper>

      <MapContainer>
        <div id="map" />
        <Marker />
        <LocationButton onClick={getCurrentPosition}>
          <LocationIcon />
        </LocationButton>
      </MapContainer>
      <AppButton
        onClick={onAddPlaceClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        customId={'add-place-button'}
        zIndex={1000}
      >
        {t('continue')}
      </AppButton>
      {windowWidth >= 450 && (
        <SkipButton onClick={onSkipClick}>{t('skip')}</SkipButton>
      )}
      {showAddPopup && (
        <AppModal isOpen={showAddPopup}>
          <PlaceAddedPopup />
        </AppModal>
      )}
    </AppContainer>
  );
};

export default AddPlace;
