import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  isAndroid,
  isDesktop,
  isIOS,
  osName,
  osVersion,
} from 'react-device-detect';
import FacebookPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { setEventDataList } from '../../store/events';
import { selectWebp } from '../../store/ui';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import {
  appendLink,
  PlansImagesLinks,
  PlansWebpImagesLinks,
} from '../../utils/utils';
import { queryParser } from 'onboarding-initialization';
import { setLandingType } from '../../store/plans';
import {
  selectUserUuid,
  getPaymentSettings,
  getUserInfo,
  selectPaymentSettingsStatus,
} from '../../store/signup';
import { FB_PIXEL_ID } from '../../analytics/facebook';
import { setLoader } from '../../store/loader';

const { REACT_APP_GOOGLE_TRACKING_ID } = process.env;

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  const [eventParams, setEventParams] = useState(null);
  const userUuid = useSelector(selectUserUuid);
  const status = useSelector(selectPaymentSettingsStatus);
  const navigate = useNavigate();
  const isWebp = useSelector(selectWebp);
  const location = useLocation();

  useEffect(() => {
    REACT_APP_GOOGLE_TRACKING_ID &&
      ReactGA.initialize(REACT_APP_GOOGLE_TRACKING_ID);
  }, []);

  useEffect(() => {
    if (eventParams) return;

    const landingParam = location.pathname.replace(/\//g, '');
    const { fbclid, gclid, t, ...utms } = queryParser(location.search);
    const facebookUtm = fbclid ? 'facebook' : null;
    const appDomain = `${window.location.origin}${
      landingParam.length ? `/${landingParam}` : ''
    }`;
    const utmSource = utms.utm_source || facebookUtm || 'organic';
    const landingType = landingParam.length ? landingParam : 'fullPrice';
    utms.utm_source = utmSource;

    if (t) {
      dispatch(getUserInfo(t));
      dispatch(getPaymentSettings(t));
    } else {
      navigate('/');
    }

    landingType.length && dispatch(setLandingType(landingType));

    const localEventsData = {
      landing_type: `app_${landingType}`,
      app_domain: appDomain,
      app_name: 'GeoZilla',
      landingType,
      appDomain,
      Remarketing: landingType,
      appName: 'GeoZilla',
      fbclid,
      gclid,
      utm: utms,
      utm_source: utmSource,
      os: `${osVersion} ${osName}`,
    };

    const userProperties = {
      AppDomain: appDomain,
      OSDevice: `${
        isDesktop ? 'desktop' : isIOS ? 'iOS' : isAndroid ? 'Android' : osName
      }`,
    };

    dispatch(setEventDataList(localEventsData));
    setAmplitudeUserProperties(userProperties);
    setEventParams(localEventsData);
  }, [dispatch, eventParams, location, navigate]);

  useEffect(() => {
    if (!userUuid) return;
    FacebookPixel.init(FB_PIXEL_ID, { external_id: userUuid });
    FacebookPixel.pageView();
  }, [userUuid]);

  useEffect(() => {
    if (!eventParams) return;
    sendAnalyticsEvents(EVENTS.onboardingStarted, eventParams);
  }, [eventParams]);

  useEffect(() => {
    isWebp
      ? PlansWebpImagesLinks.map((href) => appendLink(href))
      : PlansImagesLinks.map((href) => appendLink(href));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLoader(true));
    return () => dispatch(setLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status && userUuid) {
      navigate(status === 'success' ? '/checkout' : '/');
    }
  }, [status, userUuid, navigate]);

  return <></>;
};

export default WelcomeScreen;
