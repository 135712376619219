import styled from 'styled-components';

const StyledTitleContainer = styled.div`
  margin: ${({ marginContainer }) => marginContainer || '0px'};
  max-width: ${({ containerWidth }) => containerWidth || '300px'};
`;

const StyledTitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_38};
  color: ${(props) => props.theme.colors.title};
  letter-spacing: -0.005em;
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

const AppTitle = ({ marginContainer, title, containerWidth, textAlign }) => (
  <StyledTitleContainer
    marginContainer={marginContainer}
    containerWidth={containerWidth}
  >
    <StyledTitle textAlign={textAlign}>{title}</StyledTitle>
  </StyledTitleContainer>
);

export default AppTitle;
