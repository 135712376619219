import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { selectPlace } from '../../store/info';
import { selectEventsData } from '../../store/events';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectWebp } from '../../store/ui';

const ImgWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;
const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 24px;
`;

const StyledPopup = styled.div`
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.main};
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  position: relative;
`;

const PopupContent = styled.div`
  padding: 0px 16px 24px;
`;

const Title = styled.h1`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_31};
  color: ${(props) => props.theme.colors.title};
  text-align: center;
  margin: 0 0 8px 0;
`;

const Description = styled.p`
  width: 250px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_21};
  color: ${(props) => props.theme.colors.title};
  margin-bottom: 24px;
  text-align: center;
`;

const PlaceAddedPopup = () => {
  const { t } = useTranslation();
  const place = useSelector(selectPlace);
  const eventsData = useSelector(selectEventsData);
  const navigate = useNavigate();
  const isWebp = useSelector(selectWebp);
  const onContinueClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingPlaceaddedSubmitted, eventsData);
    navigate('/signup');
  };

  return (
    <StyledPopup>
      <ImgWrapper>
        <StyledImage
          src={
            isWebp
              ? '../assets/map_track/small_map.webp'
              : '../assets/map_track/small_map.png'
          }
          alt="Map"
        />
      </ImgWrapper>
      <PopupContent>
        <Title>{`${t(place) || t('home')} ${t('is_added')}`}</Title>
        <Description>{t('place_description')}</Description>
        <AppButton onClick={onContinueClick} customBeforeBg={'none'}>
          {t('continue')}
        </AppButton>
      </PopupContent>
    </StyledPopup>
  );
};

export default PlaceAddedPopup;
