import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    show: false,
    type: false,
    text: null,
  },
  reducers: {
    setError: (state, action) => {
      const { show, type, text } = action.payload;

      state.show = show;
      state.type = type;
      state.text = text;
    },
  },
});

export const { setError } = errorSlice.actions;

export const selectError = (state) => state.error;

export default errorSlice.reducer;
