import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { selectError, setError } from '../store/error';
import { useTranslation } from 'react-i18next';

const StyledAlert = styled.div`
  position: absolute;
  width: 90%;
  height: auto;
  max-width: 327px;
  left: 50%;
  top: 6%;
  transform: translate(-50%, -20%);
  min-height: 50px;
  background-color: ${({ show }) => (show ? '#FF5B5B' : 'transparent')};
  display: ${({ show }) => (show ? 'block' : 'none')};
  border-radius: 8px;
  padding: 16px 40px 16px 16px;
  border: none;
  z-index: 100;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    font-weight: 300;
    letter-spacing: -0.2px;
  }

  button.close {
    opacity: 1;
    width: 24px;
    height: 24px;
    padding: 0;
    top: 50%;
    right: 16px;
    margin-top: -12px;

    &:focus {
      outline: none;
    }
  }

  span {
    color: #fff;
    box-shadow: none;
    font-weight: 400;
    text-shadow: none;
  }
`;

const AppAlert = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clearError = useCallback(
    () => dispatch(setError({ show: false, text: '', type: '' })),
    [dispatch]
  );

  const doesEmailExist =
    error &&
    error.text &&
    typeof error.text === 'string' &&
    error.text.replace('.', '').toLowerCase() ===
      'the email has already been taken';

  useEffect(() => {
    if (error.show) {
      setTimeout(clearError, 3000);
    }
  }, [error, clearError]);

  if (error.show && typeof document !== 'undefined' && document.body) {
    return createPortal(
      <StyledAlert show={error.show} onClose={clearError}>
        <p>{doesEmailExist ? t('email_exist') : error.text}</p>
      </StyledAlert>,
      document.body
    );
  }
  return null;
};

export default AppAlert;
