import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import errorReducer from './error';
import eventsReducer from './events';
import signupReducer from './signup';
import infoReducer from './info';
import loaderReducer from './loader';
import uiReducer from './ui';
import checkoutReducer from './checkout';
import plansReducer from './plans';

const MIGRATION_DEBUG = false;

const migrations = {
  0: (state) => ({
    ...state,
  }),
};

const reducers = combineReducers({
  error: errorReducer,
  events: eventsReducer,
  signup: signupReducer,
  info: infoReducer,
  loader: loaderReducer,
  ui: uiReducer,
  checkout: checkoutReducer,
  plans: plansReducer,
});

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['signup', 'ui', 'info', 'events', 'plans'],
  version: 0,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV === 'stage',
  middleware: [thunk],
});
