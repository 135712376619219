import { useSelector } from 'react-redux';
import { selectEventsData } from '../store/events';
import { sendAnalyticsEvents } from '../analytics/common';

export const useSendEvents = ({ extraPathname = '' } = {}) => {
  const eventsData = useSelector(selectEventsData);

  return (eventName, eventParams = {}) => {
    // console.count(eventName); // uncomment to check event duplication
    const pathName = {
      screen_name: (
        eventParams?.screen_name ||
        extraPathname ||
        window.location.pathname
      ).replace('/', ''),
    };
    const extendedEventsData = { ...eventsData, ...eventParams, ...pathName };
    // console.log(eventName, extendedEventsData);
    sendAnalyticsEvents(eventName, extendedEventsData);
  };
};
