import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PlansScreen from '../pages/plans/PlansScreen';
import CheckoutScreen from '../pages/checkout/CheckoutScreen';
import withStripeInit from '../hoc/withStripeInit';
import { selectLandingType, selectShowCheckout } from '../store/plans';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CheckoutContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? 'block' : 'none')};
  height: 100%;
`;

const PlanContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? 'none' : 'block')};
`;

const PlanCheckout = (props) => {
  const showCheckout = useSelector(selectShowCheckout);
  const landingType = useSelector(selectLandingType);
  const isRemarketing = landingType === 'fdtrial';

  return (
    <Wrapper>
      <PlanContainer showCheckout={showCheckout}>
        <PlansScreen />
      </PlanContainer>
      {!isRemarketing && (
        <CheckoutContainer showCheckout={showCheckout}>
          <CheckoutScreen {...props} />
        </CheckoutContainer>
      )}
    </Wrapper>
  );
};

export default withStripeInit(PlanCheckout);
