import styled from 'styled-components';

const StyledTitleContainer = styled.div`
  margin: ${({ marginContainer }) => marginContainer || '0px'};
  max-width: ${({ containerWidth }) => containerWidth || '300px'};
`;

const StyledTitle = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.subtitle};
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

const AppSubtitle = ({
  marginContainer,
  subtitle,
  containerWidth,
  textAlign,
}) => (
  <StyledTitleContainer
    marginContainer={marginContainer}
    containerWidth={containerWidth}
  >
    <StyledTitle textAlign={textAlign}>{subtitle}</StyledTitle>
  </StyledTitleContainer>
);

export default AppSubtitle;
