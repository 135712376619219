import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectEventsData } from '../../store/events';
import AppContainer from '../../components/AppContainer';
import AppCircularProgress from '../../components/AppCircularProgress';
import { selectWebp } from '../../store/ui';
import { PlansWebpImagesLinks, PlansImagesLinks } from '../../utils/utils';
import useWindowWidth from '../../hooks/useWindowWidth';
import { usePreloadImages } from '../../hooks/useImagePreloader';
import { selectPaymentSettingsStatus } from '../../store/signup';

const isArabic = navigator.language.startsWith('ar');
const isStage = process.env.REACT_APP_ENV === 'stage';

const Title = styled.h1`
  max-width: 330px;
  text-align: center;
  margin: 0 0 8px 0;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_42};
  color: ${(props) => props.theme.colors.title};
`;

const Description = styled.p`
  max-width: 350px;
  text-align: center;
  margin-bottom: 50px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_21};
  color: ${(props) => props.theme.colors.title};

  @media screen and (max-width: 380px) {
    margin-bottom: 40px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 64px 0 0 0;
  padding: 2px;
  flex-wrap: wrap;
  max-width: 550px;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
    justify-content: space-evenly;
  }

  @media screen and (max-width: 380px) {
    margin: 50px 0 0 0;
  }

  li {
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
    color: ${(props) => props.theme.colors.title};
    display: flex;
    justify-content: flex-start;
    flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
    align-items: center;
    width: 260px;
    margin-bottom: 12px;
  }
`;

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  color: ${({ theme }) => theme.colors.main};
  margin-right: 10px;
  margin-left: 10px;
`;

const CheckIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${'../assets/profile/CheckIcon.svg'});
`;

const EmptyIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${'../assets/profile/EmptyIcon.svg'});
`;

const ProfileScreen = () => {
  const navigate = useNavigate();
  const eventsData = useSelector(selectEventsData);
  const [progress, setProgress] = useState(0);
  const loadingDuration = 6000;
  const windowWidth = useWindowWidth();
  const isWebp = useSelector(selectWebp);
  const { t } = useTranslation();
  const settingsStatus = useSelector(selectPaymentSettingsStatus);

  const doneCallback = () => {
    if (settingsStatus) {
      navigate('/checkout');
    }
  };

  usePreloadImages(isWebp ? PlansWebpImagesLinks : PlansImagesLinks);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setProgress(progress + 1);
    }, loadingDuration / 100);

    if (progress === 100) doneCallback();
    return () => {
      clearTimeout(loadingTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const onPercentClick = () => {
    isStage && doneCallback();
  };

  useEffect(
    () => sendAnalyticsEvents(EVENTS.onboardingCreatingProfile, eventsData),
    [eventsData]
  );

  return (
    <AppContainer customPadding={windowWidth <= 450 && '44px 24px 24px'}>
      <Title>{t('creating_your_profile')}</Title>
      <Description>{t('creating_description')}</Description>
      <AppCircularProgress
        size={windowWidth <= 375 ? 158 : 180}
        progress={progress}
        trackWidth={10}
        indicatorWidth={10}
      />
      <StyledList>
        <li
          onClick={onPercentClick}
          id={isStage && 'test-creating-profile-skip'}
        >
          <StyledStatus isActive={progress >= 25}>
            {progress >= 25 ? <CheckIcon /> : <EmptyIcon />}
          </StyledStatus>
          <span>{t('checking_current_location')}</span>
        </li>
        <li>
          <StyledStatus isActive={progress >= 50}>
            {progress >= 50 ? <CheckIcon /> : <EmptyIcon />}
          </StyledStatus>
          <span>{t('adding_place_alerts')}</span>
        </li>
        <li>
          <StyledStatus isActive={progress >= 75}>
            {progress >= 75 ? <CheckIcon /> : <EmptyIcon />}
          </StyledStatus>
          <span>{t('safety_features')}</span>
        </li>
        <li>
          <StyledStatus isActive={progress >= 95}>
            {progress >= 95 ? <CheckIcon /> : <EmptyIcon />}
          </StyledStatus>
          <span>{t('activating_location_history')}</span>
        </li>
      </StyledList>
    </AppContainer>
  );
};

export default ProfileScreen;
