import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.overlay_bg};
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.main};
  border-radius: 24px;
  max-width: 310px;
  width: 100%;
  max-height: 80vh;
`;

const AppModal = ({ isOpen, children }) => (
  <ModalWrapper isOpen={isOpen}>
    <Overlay isOpen={isOpen} />
    <ModalContent>{children}</ModalContent>
  </ModalWrapper>
);

export default AppModal;
