import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import AppSubtitle from '../../components/AppSubtitle';
import AppTitle from '../../components/AppTitle';
import { selectEventsData } from '../../store/events';
import { selectWebp } from '../../store/ui';
import useImagesSrc from '../../hooks/useImagesSrc';
import { selectLandingAnimation } from '../../store/plans';
import useWindowWidth from '../../hooks/useWindowWidth';
import { usePreloadImages } from '../../hooks/useImagePreloader';

const StyledImage = styled.div`
  background-image: ${({ imgSource }) => imgSource && `url('${imgSource}')`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  width: 100vw;
  max-height: 300px;
  height: 100%;
  @media screen and (min-width: 376px) {
    background-image: ${({ imgSource }) => imgSource && `url('${imgSource}')`};
    max-height: 512px;
    background-size: ${({ isAnim }) => (isAnim ? '100%' : '150%')};
    background-position: center;
  }
  @media screen and (min-width: 450px) {
    background-size: contain;
    min-height: 150px;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  // max-height: 80px;
  min-height: 150px;
  min-width: 330px;
  margin-bottom: 34px;
  @media screen and (min-width: 450px) {
    margin-bottom: 20px;
  }
`;

const PrivateChat = () => {
  const navigate = useNavigate();
  const eventsData = useSelector(selectEventsData);
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);
  const { imagesSources } = useImagesSrc();
  const isAnimationLanding = useSelector(selectLandingAnimation);
  const windowWidth = useWindowWidth();
  usePreloadImages(imagesSources.lowBattery);
  useEffect(
    () => sendAnalyticsEvents(EVENTS.onboardingChat, eventsData),
    [eventsData]
  );

  const onContinueClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingChatSubmitted, eventsData);
    navigate('/low-battery-notification');
  };

  const imgSource = isAnimationLanding
    ? '../assets/desktop/anim/Private_chat.gif'
    : windowWidth >= 375 && isWebp
    ? '../assets/desktop/webp/Private_chat.webp'
    : windowWidth >= 375 && !isWebp
    ? '../assets/desktop/png/Private_chat.png'
    : windowWidth < 375 && isWebp
    ? '../assets/mobile/webp/Private_chat.webp'
    : '../assets/mobile/png/Private_chat.png';

  return (
    <>
      <StyledImage imgSource={imgSource} isAnim={isAnimationLanding} />
      <StyledContent>
        <AppTitle
          title={t('private_chat')}
          marginContainer={'0px 0px 12px 0px'}
        />
        <AppSubtitle
          containerWidth={'310px'}
          subtitle={t('private_chat_description')}
        />
      </StyledContent>
      <AppButton
        onClick={onContinueClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
      >
        {t('continue')}
      </AppButton>
    </>
  );
};

export default PrivateChat;
