import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  setPlan,
  selectLandingType,
  selectPlan,
  setDefaultPlanId,
  selectShowCheckout,
} from '../../store/plans';
import AppContainer from '../../components/AppContainer';
import useWindowWidth from '../../hooks/useWindowWidth';
import PlansDescription from './components/PlansDescription';
import AppButton from '../../components/AppButton';
import { selectSkipPaywallDisabled } from '../../store/signup';
import CrossBtn from './components/CrossBtn';
import { CrossBtnContainer } from './DefaultPlan';

const UnlockTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
`;

const UnlockWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gradient_plan};
  max-width: 330px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  border-radius: 16px;
`;

const OfferTitle = styled.div`
  letter-spacing: -0.46;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.extraBold};
  font-size: ${(props) => props.theme.fontSizes.font_30};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_30};
  color: ${(props) => props.theme.colors.main};
  font-family: 'SF Pro Text';
  margin-bottom: 8px;
`;

const OfferDescription = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_16};
  color: ${(props) => props.theme.colors.light};
  font-family: 'SF Pro Text';
`;

const FreeTrialPlan = ({ handleClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const landingType = useSelector(selectLandingType);
  const plan = useSelector(selectPlan);
  const isSkipPaywallDisabled = useSelector(selectSkipPaywallDisabled);
  const showCheckout = useSelector(selectShowCheckout);
  const FREETRIAL_PLANS = useMemo(
    () => ({
      'freetrial-monthly': [
        {
          id: 'gz-1-month-free3d-1499',
          title: `${t('3_days_free')}`,
          description: `${t('freetrialmonthly_description')}`,
        },
      ],
      'freetrial-weekly': [
        {
          id: 'gz-1-month-free3d-1996',
          title: `${t('3_days_free')}`,
          description: `${t('freetrialweekly_description')}`,
        },
      ],
      'month-trial': [
        {
          id: 'gz-1-month-intro1m-999-2999',
          title: `${t('1_month_trial')}`,
          description: `${t('monthtrial_description')}`,
        },
      ],
    }),
    [t]
  );

  const currentPlans = useMemo(
    () => FREETRIAL_PLANS[landingType],
    [landingType, FREETRIAL_PLANS]
  );

  const planHints = useMemo(
    () => ({
      'gz-1-month-free3d-1499': null,
      'gz-1-month-free3d-1996': `${t(`${landingType}_hint`)}`,
      'gz-1-month-intro1m-999-2999': `${t(`${landingType}_hint`)}`,
    }),
    [t, landingType]
  );

  const renderOffer = (i) => (
    <UnlockWrapper key={i}>
      <OfferTitle>{i.title}</OfferTitle>
      <OfferDescription dangerouslySetInnerHTML={{ __html: i.description }} />
    </UnlockWrapper>
  );

  useEffect(() => {
    if (showCheckout) return;
    dispatch(setPlan(currentPlans[0].id));
    dispatch(setDefaultPlanId(currentPlans[0].id));
  }, [dispatch, currentPlans, showCheckout]);

  const continueButton = (displayStickyBtn, sticky, id) => (
    <AppButton
      customStickyWidth={'100%'}
      onClick={handleClick}
      bottomPosition={'24px'}
      displayStickyBtn={displayStickyBtn}
      position={sticky}
      customBeforeHeight={false}
      displayStubBtn={false}
      customId={`paywall-continue-button-${id}`}
    >
      {t('continue')}
    </AppButton>
  );

  return (
    <AppContainer
      customPadding={windowWidth >= 450 ? '72px 24px 24px' : '30px 24px 24px'}
      customHeight={'auto'}
    >
      <CrossBtnContainer>
        {!isSkipPaywallDisabled && <CrossBtn />}
      </CrossBtnContainer>
      <UnlockTitle>{t('unlock_features')}</UnlockTitle>
      {currentPlans.map(renderOffer)}
      <PlansDescription
        continueButton={continueButton}
        btnAfter
        showHint={planHints[plan]}
      />
    </AppContainer>
  );
};

export default FreeTrialPlan;
