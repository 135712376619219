import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    show: false,
    type: '',
  },
  reducers: {
    setLoader: (state, action) => {
      state.show = action.payload;
    },
    setCheckoutLoader: (state, action) => {
      const { show, type } = action.payload;

      state.show = show;
      state.type = type;
    },
  },
});

export const { setLoader, setCheckoutLoader } = loaderSlice.actions;

export const selectLoader = (state) => state.loader.show;
export const selectLoaderType = (state) => state.loader.type;

export default loaderSlice.reducer;
