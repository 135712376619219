import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import { selectEventsData, setEventData } from '../../store/events';
import AppTitle from '../../components/AppTitle';
import { setTarget } from '../../store/info';
import { PlaceTrackImagesLinks } from '../../utils/utils';
import { usePreloadImages } from '../../hooks/useImagePreloader';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

export const StyledList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0 0 65px 0;
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  flex-wrap: wrap;
  column-gap: 27px;
  row-gap: 27px;
  width: 100%;
  max-width: 327px;
`;

export const StyledItemWrapper = styled.div`
  width: 91px;
  display: flex;
  align-items: center;
  flex-direction: column;
  > span {
    margin-top: 12px;
    color: ${({ active, theme }) =>
      active ? theme.colors.btn : theme.colors.subtitle};
    text-align: center;
    font-weight: ${(props) => props.theme.fontWeights.light};
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  }
  :last-child {
    img {
      width: 31px;
      height: 31px;
    }
  }
`;

export const StyledItem = styled.div`
  width: 91px;
  height: 91px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.selected_bg : theme.colors.inactive_choise};
  border: ${({ active, theme }) =>
    active ? `2px solid ${theme.colors.btn}` : '2px solid transparent'};
  transition: background-color 300ms, border 300ms;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > img {
    width: 51px;
    height: 51px;
  }
`;

export const ContentWrapper = styled.div`
  min-height: auto;
`;

const TargetTrack = () => {
  const navigate = useNavigate();
  const eventsData = useSelector(selectEventsData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [whoTrack, setWhoTrack] = useState(null);
  const isDisabled = !!whoTrack;
  const BUTTONS = ['child', 'partner', 'parent', 'pet', 'car', 'other'];
  usePreloadImages(PlaceTrackImagesLinks);

  useEffect(
    () => sendAnalyticsEvents(EVENTS.onboardingTrack, eventsData),
    [eventsData]
  );

  const onContinueClick = () => {
    dispatch(setEventData({ id: 'TrackWho', data: whoTrack }));
    sendAnalyticsEvents(EVENTS.onboardingTrackSubmitted, {
      ...eventsData,
      TrackWho: whoTrack,
    });
    dispatch(setTarget(whoTrack));
    navigate('/choose-place');
  };

  const renderItem = (i, index) => {
    const isActive = i === whoTrack;
    return (
      <StyledItemWrapper active={isActive} key={index}>
        <StyledItem
          onClick={() => setWhoTrack(i)}
          active={isActive}
          key={index}
          id={`option-${index + 1}`}
        >
          <img
            src={
              isActive
                ? `../assets/whom_track/${i}_active.png`
                : `../assets/whom_track/${i}.png`
            }
            alt={t(i)}
          />
        </StyledItem>
        <span>{t(i)}</span>
      </StyledItemWrapper>
    );
  };

  return (
    <>
      <ContentWrapper>
        <AppTitle
          textAlign={textAlign}
          containerWidth={'327px'}
          title={t('who_will_tracked')}
          marginContainer={'0px 0px 35px 0px'}
        />
        <StyledList>{BUTTONS.map(renderItem)}</StyledList>
      </ContentWrapper>
      <AppButton
        onClick={onContinueClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        disabled={!isDisabled}
        displayStubBtn={false}
      >
        {t('continue')}
      </AppButton>
    </>
  );
};

export default TargetTrack;
