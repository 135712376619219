import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import RemarketingPaywall from './RemarketingPaywall';
import {
  selectEventsData,
  setAnalyticsData,
  setEventDataList,
} from '../../store/events';
import {
  selectLandingType,
  selectPlan,
  selectShowCheckout,
  setPlanPrices,
  setShowCheckout,
} from '../../store/plans';
import { PLANS } from '../../analytics/plans';
import FreeTrialPlan from './FreeTrialPlan';
import DiscountPlan from './DiscountPlan';
import DefaultPlan from './DefaultPlan';
import { selectWebp } from '../../store/ui';
import {
  appendLink,
  checkoutImagesLinks,
  checkoutWebpImagesLinks,
} from '../../utils/utils';
import { useAnalyticsData } from '../../utils/checkoutHelper';
import { selectStripeAccountName } from '../../store/signup';

const PlansScreen = () => {
  const landingType = useSelector(selectLandingType);
  const isRemarketing = landingType === 'fdtrial';
  const dispatch = useDispatch();
  const isWebp = useSelector(selectWebp);
  const eventsData = useSelector(selectEventsData);
  const plan = useSelector(selectPlan);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const isFreeTrialPlan =
    landingType === 'freetrial-monthly' ||
    landingType === 'freetrial-weekly' ||
    landingType === 'month-trial';
  const isDiscountPlan = landingType === 'new-discount';
  const analyticsParams = useAnalyticsData();
  const showCheckout = useSelector(selectShowCheckout);
  useEffect(() => {
    dispatch(setAnalyticsData(analyticsParams));
  }, [analyticsParams, dispatch]);

  useEffect(() => {
    isWebp
      ? checkoutWebpImagesLinks.map((href) => appendLink(href))
      : checkoutImagesLinks.map((href) => appendLink(href));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      !showCheckout &&
        sendAnalyticsEvents(EVENTS.onboardingPaywallShown, eventsData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCheckout]
  );

  const onContinueClick = () => {
    const { productId, duration, description, price, periodPrice } =
      PLANS[plan];
    const planData = {
      Value: price,
      value: Math.round(periodPrice * 100),
      currency: 'USD',
      price: periodPrice,
      Duration: duration,
      duration,
      description,
      periodPrice,
      product_id: productId,
      stripe_account_name: stripeAccountName,
    };
    dispatch(setEventDataList(planData));
    dispatch(
      setPlanPrices({
        monthlyPrice: price,
        paymentPrice: periodPrice,
      })
    );
    sendAnalyticsEvents(EVENTS.onboardingPaywallChosen, {
      ...eventsData,
      ...planData,
    });
    dispatch(setShowCheckout(true));
  };

  if (isFreeTrialPlan) {
    return <FreeTrialPlan handleClick={onContinueClick} />;
  }
  if (isDiscountPlan) {
    return <DiscountPlan handleClick={onContinueClick} />;
  }
  if (isRemarketing) {
    return <RemarketingPaywall />;
  }
  return <DefaultPlan handleClick={onContinueClick} />;
};

export default PlansScreen;
