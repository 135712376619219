import { useEffect, useState } from 'react';

const getStickyButtonStyles = (bottomPadding, buttonHeight) => {
  const { height, offsetTop } = window?.visualViewport || {};
  if (height) {
    if (window.innerHeight !== height) {
      return {
        style: {
          top: `${height + (offsetTop || 0) - bottomPadding - buttonHeight}px`,
        },
      };
    }
  }
  return { style: { bottom: `${bottomPadding}px` } };
};

export const useStickyButtonStyles = (
  bottomPadding = 24,
  buttonHeight = 44
) => {
  const [stickyButtonStyles, setStickyButtonStyles] = useState(() =>
    getStickyButtonStyles(bottomPadding, buttonHeight)
  );

  useEffect(() => {
    const setStickyStyles = () =>
      setStickyButtonStyles(getStickyButtonStyles(bottomPadding, buttonHeight));
    window?.visualViewport?.addEventListener('resize', setStickyStyles);
    window?.visualViewport?.addEventListener('scroll', setStickyStyles);
    return () => {
      window?.visualViewport?.removeEventListener('resize', setStickyStyles);
      window?.visualViewport?.removeEventListener('scroll', setStickyStyles);
    };
  }, [bottomPadding, buttonHeight]);

  return { stickyButtonStyles };
};
