import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import AppContainer from '../../components/AppContainer';
import { selectEmail, selectSkipPaywallDisabled } from '../../store/signup';
import AppTimer from '../../components/AppTimer';
import AppTimerShort from '../../components/AppTimerShort';
import {
  selectPlan,
  setPlan,
  selectLandingType,
  setDefaultPlanId,
  selectShowCheckout,
} from '../../store/plans';
import AppButton from '../../components/AppButton';
import PlansDescription from './components/PlansDescription';
import useWindowWidth from '../../hooks/useWindowWidth';
import CrossBtn from './components/CrossBtn';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

export const CrossBtnContainer = styled.div`
  position: relative;
  width: 375px;
  height: 40px;
`;

export const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_40};
  color: ${(props) => props.theme.colors.title};
  margin: 0 0 8px 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 360px;
  width: 100%;
  > span {
    color: ${(props) => props.theme.colors.btn};
  }
`;

export const Description = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  margin: 0 0 24px;
  text-align: center;
  max-width: 330px;
`;

const StyledList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 35px 0 24px 0;
  list-style: none;
  max-width: 330px;
`;
const StyledLi = styled.li`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: space-between;
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.gradient_plan : theme.colors.main};
  border: ${({ active, theme }) =>
    active
      ? `1px solid ${theme.colors.main}`
      : `1px solid ${theme.colors.borderColor}`};
  color: ${({ active, theme }) =>
    active ? theme.colors.main : theme.colors.description};
  border-radius: 16px;
  width: 100%;
  min-height: 74px;
  padding: 7px 0;
  position: relative;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledPeriod = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    margin: 0 14px;
  }
  span {
    font-weight: ${({ active, theme }) =>
      active ? theme.fontWeights.medium : theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
`;

const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 7px;
  margin-left: 7px;
  margin-${textAlign}: 0;
  padding: 8px 14px;
  background-color: ${(props) => props.theme.colors.main};
  color: ${({ active, theme }) =>
    active ? theme.colors.most_popular : theme.colors.inactivePrice};
  border-radius: 12px;

  ${({ withLabel }) =>
    withLabel &&
    `&:not(:last-child) {
      margin-top: 13px;
    }`}
`;

const StyledOldValue = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_16};
  color: ${(props) => props.theme.colors.oldPrice};
  text-decoration: line-through;
`;

const StyledValue = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_22};
  letter-spacing: -0.2px;
`;

const StyledPerWeek = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_8};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_10};
`;

const BestLabel = styled.div`
  position: absolute;
  top: 0;
  ${textAlign}: 12px;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 7px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_14};
  color: ${(props) => props.theme.colors.most_popular};
  text-transform: uppercase;
  transform: translateY(-50%);
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25);
  padding: 5px 16px;
  border: 1px solid ${({ theme }) => theme.colors.most_popular};
`;

const SaveAmount = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 7px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_14};
  color: ${(props) => props.theme.colors.most_popular};
  text-transform: uppercase;
  transform: translateY(-50%);
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25);
  padding: 5px 6px;
  border: 1px solid ${({ theme }) => theme.colors.most_popular};
`;

export const WhiteIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url('../assets/plans/WhiteIcon.svg');
`;

export const SelectedIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url('../assets/plans/SelectedIcon.svg');
`;

const DefaultPlan = ({ handleClick }) => {
  const email = useSelector(selectEmail);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const windowWidth = useWindowWidth();
  const landingType = useSelector(selectLandingType);
  const isSkipPaywallDisabled = useSelector(selectSkipPaywallDisabled);
  const showCheckout = useSelector(selectShowCheckout);
  const fullPricePlans = useMemo(
    () => [
      {
        id: 'gz-1-month-3999',
        title: '1',
        period: t('month'),
        price: '$9.99',
        week: `${t('per_week')}`,
        label: t('most_popular'),
      },
      {
        id: 'gz-3-month-5999',
        title: '3',
        period: t('months'),
        price: '$4.99',
        week: t('per_week'),
      },
      {
        id: 'gz-6-month-panther',
        title: '6',
        period: t('months'),
        price: '$2.99',
        week: t('per_week'),
        // saveAmount: '76%',
      },
    ],
    [t]
  );
  const DEFAULT_PLANS = useMemo(
    () => ({
      fullPrice: fullPricePlans,
      anim: fullPricePlans,
      new: [
        {
          id: 'gz-6-month-3599',
          title: '6',
          period: t('months'),
          price: '$1.49',
          oldPrice: '$2.99',
          week: t('per_week'),
          label: t('special_for_you'),
          saveAmount: '76%',
        },
        {
          id: 'gz-3-months-2999',
          title: '3',
          period: t('months'),
          price: '$2.49',
          oldPrice: '$4.99',
          week: t('per_week'),
        },
        {
          id: 'gz-1-month-1999',
          title: '1',
          period: t('month'),
          price: '$4.99',
          oldPrice: '$9.99',
          week: `${t('per_week')}`,
        },
      ],
    }),
    [t, fullPricePlans]
  );

  const currentPlans = useMemo(
    () => DEFAULT_PLANS[landingType],
    [landingType, DEFAULT_PLANS]
  );

  useEffect(() => {
    if (showCheckout) return;
    dispatch(setPlan(currentPlans[0].id));
    dispatch(setDefaultPlanId(currentPlans[0].id));
  }, [dispatch, currentPlans, showCheckout]);

  const onPlanClick = (id) => dispatch(setPlan(id));

  const renderOffer = (i, index) => {
    const isActive = plan === i.id;
    return (
      <StyledLi
        active={isActive}
        onClick={() => onPlanClick(i.id)}
        key={i.id}
        id={`plan-button-${index + 1}`}
      >
        <StyledPeriod active={isActive}>
          <div>{isActive ? <SelectedIcon /> : <WhiteIcon />}</div>
          <span>
            {i.title} {i.period}
          </span>
        </StyledPeriod>
        <StyledPrice active={i.label} withLabel={i.saveAmount}>
          {i.oldPrice && <StyledOldValue>{i.oldPrice}</StyledOldValue>}
          <StyledValue>{i.price}</StyledValue>
          <StyledPerWeek>{i.week}</StyledPerWeek>
        </StyledPrice>
        {i.label && <BestLabel>{i.label}</BestLabel>}
        {i.saveAmount && (
          <SaveAmount>
            {t('save')} {i.saveAmount}
          </SaveAmount>
        )}
      </StyledLi>
    );
  };

  const continueButton = (displayStickyBtn, sticky, id) => (
    <AppButton
      onClick={handleClick}
      bottomPosition={'24px'}
      displayStickyBtn={displayStickyBtn}
      position={sticky}
      customStickyWidth={'100%'}
      customBeforeHeight={false}
      displayStubBtn={false}
      customId={`paywall-continue-button-${id}`}
    >
      {t('continue')}
    </AppButton>
  );

  return (
    <AppContainer
      customPadding={
        windowWidth >= 450
          ? '72px 24px 24px'
          : windowWidth <= 380
          ? '5px 24px 24px'
          : '30px 24px 24px'
      }
      customHeight={'auto'}
    >
      <CrossBtnContainer>
        {!isSkipPaywallDisabled && <CrossBtn />}
      </CrossBtnContainer>
      <Title>
        {`${t('hey')}`}{' '}
        <span>{`${decodeURIComponent(email).split('@')[0]}`}!</span>
      </Title>
      <Description>{t('based')}</Description>
      {landingType === 'new' ? <AppTimer /> : <AppTimerShort />}
      <StyledList>{currentPlans.map(renderOffer)}</StyledList>
      {continueButton(true, 'sticky', 1)}
      <PlansDescription continueButton={continueButton} />
    </AppContainer>
  );
};

export default DefaultPlan;
