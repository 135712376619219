import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : '')} 0.3s ease-out;
  transition: opacity 0.5s ease-out;
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
`;

export const SuccessPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px 16px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #f0efef;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 1;
  animation: ${fadeIn} 0.5s ease-out;
`;

export const SuccessText = styled.div`
  margin-top: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #323232;
`;

export const SuccessSubText = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #90959a;
`;
