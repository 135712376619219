import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { selectEventsData } from '../store/events';
import { selectUserUuid } from '../store/signup';
import { FBC_PARAM, FBP_PARAM, FB_PIXEL_ID } from '../analytics/facebook';
import {
  getGoogleClientID,
  REACT_APP_GOOGLE_TRACKING_ID,
  GOOGLE_ADS_ACCOUNT_ID,
} from '../analytics/GA';

export const useAnalyticsData = () => {
  const [data, setData] = useState({});
  const [cid, setCid] = useState(null);
  const eventsData = useSelector(selectEventsData);
  const userUuid = useSelector(selectUserUuid);
  const { fbclid, gclid, appDomain } = eventsData;
  const AFUserID = Cookies.get('afUserId');
  const fbc = Cookies.get(FBC_PARAM);
  const fbp = Cookies.get(FBP_PARAM);

  const encodedSource = encodeURIComponent(appDomain);

  useEffect(() => getGoogleClientID(setCid), []);

  useEffect(() => {
    setData({
      appsflyer_id: AFUserID,
      google_tracking_id: REACT_APP_GOOGLE_TRACKING_ID,
      google_client_id: cid || '',
      google_ads_account_id: GOOGLE_ADS_ACCOUNT_ID,
      fb_pixel_id: FB_PIXEL_ID,
      user_data: {
        uuid: userUuid,
        fbc,
        fbp,
        source_url: encodedSource,
        fb_click_id: fbc || fbclid || '',
        google_click_id: gclid || '',
      },
    });
  }, [cid, AFUserID, userUuid, fbc, fbp, fbclid, gclid, encodedSource]);

  return data;
};
