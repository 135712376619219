import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoader } from '../store/loader';

const StyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.main};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1051;

  &:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    background-image: url('../assets/loader.gif');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 250%;
  }
`;

const Message = styled.span`
  max-width: 180px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  text-align: center;
  white-space: break-spaces;
  margin-top: 24px;
`;

const AppLoader = ({ showMessage }) => {
  const { t } = useTranslation();
  const storeShow = useSelector(selectLoader);

  if (storeShow) {
    return (
      <StyledSpinner id="loading-element">
        {showMessage && <Message>{t('loading_text')}</Message>}
      </StyledSpinner>
    );
  }

  return null;
};

export default AppLoader;
