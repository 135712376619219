const theme = {
  colors: {
    main: '#fff',
    borderColor: '#D4D4D4',
    btn: '#00BFA5',
    money_back: '#009E89',
    money_back_bg: '#E5F8F5',
    most_popular: '#018191',
    title: '#323232',
    subtitle: '#5B5B5B',
    light: '#A7E2DA',
    description: '#909090',
    trial: '#818181',
    error: '#FF5B5B',
    prevprice: '#8DD1C8',
    gradient_title: 'linear-gradient(90deg, #00BFA5 0%, #66D9C9 100%);',
    gradient_btn: 'rgba(0, 191, 165, 0.16)',
    gradient_bg: 'linear-gradient(67.83deg, #007F90 3.46%, #00BFA5 87.83%);',
    gradient_plan: 'linear-gradient(92.01deg, #00BFA5 0%, #007F90 100%);',
    rules: '#848484',
    info: '#ADADAD',
    selected_bg: 'rgba(0, 191, 165, 0.1)',
    domain: '#00A08B',
    progress_inactive: '#F0EFEF',
    inactive_choise: '#F2F2F2',
    border_scale: '#B8EDE6',
    border_darker: 'rgba(0, 0, 0, 0.04)',
    vertical_line: 'rgba(60, 60, 67, 0.36)',
    overlay_bg: 'rgba(0, 0, 0, 0.68)',
    skip_btn: '#7D7D7D',
    trackColor: '#ECECEC',
    inactivePrice: '#919191',
    oldPrice: '#8DD1C8',
    cost_bg: '#E9F4FF',
    paypal_bg: 'rgba(0, 157, 225, 0.13)',
    paypal_border: '#009DE1',
    checkout_or: '#6F6F6F',
  },
  fontSizes: {
    font_8: '8px',
    font_12: '12px',
    font_13: '13px',
    font_14: '14px',
    font_16: '16px',
    font_18: '18px',
    font_20: '20px',
    font_24: '24px',
    font_30: '30px',
    font_32: '32px',
    font_36: '36px',
    font_40: '40px',
    font_48: '48px',
  },
  fontWeights: {
    extraLight: 400,
    light: 500,
    medium: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: {
    lineHeight_8: '8px',
    lineHeight_10: '10px',
    lineHeight_12: '12px',
    lineHeight_14: '14px',
    lineHeight_16: '16px',
    lineHeight_17: '17px',
    lineHeight_18: '18px',
    lineHeight_19: '19px',
    lineHeight_20: '20px',
    lineHeight_21: '21px',
    lineHeight_22: '22px',
    lineHeight_24: '24px',
    lineHeight_26: '26px',
    lineHeight_28: '28px',
    lineHeight_29: '29px',
    lineHeight_30: '30px',
    lineHeight_31: '31px',
    lineHeight_32: '32px',
    lineHeight_36: '36px',
    lineHeight_38: '38px',
    lineHeight_40: '40px',
    lineHeight_42: '42px',
    lineHeight_48: '48px',
    lineHeight_52: '52px',
  },
  breakPoints: {
    tabletMinWidth: 432,
  },
};

export default theme;
