import { createSlice } from '@reduxjs/toolkit';

export const infoSlice = createSlice({
  name: 'info',
  initialState: {
    name: '',
    target: '',
    place: '',
  },
  reducers: {
    setName: (state, action) => {
      const value = action.payload;
      const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
      state.name = encodeURIComponent(formattedValue.trim());
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    setPlace: (state, action) => {
      state.place = action.payload;
    },
  },
});

export const { setName, setTarget, setPlace } = infoSlice.actions;

export const selectName = (state) => state.info.name;
export const selectTarget = (state) => state.info.target;
export const selectPlace = (state) => state.info.place;

export default infoSlice.reducer;
