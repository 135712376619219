import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppContainer from '../../components/AppContainer';
import { selectEmail, selectStripeAccountName } from '../../store/signup';
import { useTimerNumbers, checkNumber } from '../../components/AppTimerShort';
import {
  selectPlan,
  setPlan,
  setDefaultPlanId,
  setPlanPrices,
} from '../../store/plans';
import AppButton from '../../components/AppButton';
import PlansDescription, {
  FeaturesList,
  FeatureTitle,
  renderFeature,
} from './components/PlansDescription';
import useWindowWidth from '../../hooks/useWindowWidth';
import CheckoutScreen from '../checkout/CheckoutScreen';
import { Title } from './DefaultPlan';
import { selectAnalyticsData, setEventDataList } from '../../store/events';
import withStripeInit from '../../hoc/withStripeInit';
import { PLANS } from '../../analytics/plans';
import { selectFormValidity } from '../../store/checkout';

const TimerWrapper = styled.div`
  padding: 16px 24px;
  background: rgba(255, 197, 23, 0.1);
  border: 1px solid #ffc517;
  border-radius: 16px;
  text-align: center;
  margin: 24px 0;
`;

const TimerText = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #323232;
  > span {
    font-weight: 700;
  }
`;

const TimerCount = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ff6058;
  margin: 16px 0;
`;

const PlanWrapper = styled.div`
  padding: 13px 0;
  background: linear-gradient(92.63deg, #00bfa5 -1.48%, #007f90 102.01%);
  border-radius: 16px;
  margin: 24px 0 0;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  color: #e2f1ff;

  & > div:first-child {
    color: #fff;
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
  }
`;

const CheckoutWrapper = styled.div`
  margin: 0 0 68px;

  &:empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      width: 48px;
      height: 48px;
      background: url('../assets/loader.gif') center / 100% no-repeat;
    }
  }
`;

const Lalka = styled.div`
  max-width: 327px;

  & > * {
    width: 100%;
  }
`;

const RemarketingPaywall = ({ stripePromise }) => {
  const email = useSelector(selectEmail);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const windowWidth = useWindowWidth();
  const { min, sec } = useTimerNumbers();
  const analyticsParams = useSelector(selectAnalyticsData);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const [loadCheckout, setLoadCheckout] = useState(false);
  const paymentCallback = useRef();
  const isFormValid = useSelector(selectFormValidity);

  useEffect(() => {
    dispatch(setPlan('gz-1-month-intro14d-1.99-29.99'));
    dispatch(setDefaultPlanId('gz-1-month-intro14d-1.99-29.99'));
  }, [dispatch]);

  useEffect(() => {
    if (!plan) return;
    const { productId, duration, description, price, periodPrice } =
      PLANS[plan];
    const planData = {
      Value: price,
      value: Math.round(periodPrice * 100),
      currency: 'USD',
      price: periodPrice,
      Duration: duration,
      duration,
      description,
      periodPrice,
      product_id: productId,
      stripe_account_name: stripeAccountName,
    };
    dispatch(setEventDataList(planData));
    dispatch(
      setPlanPrices({
        monthlyPrice: price,
        paymentPrice: periodPrice,
      })
    );
  }, [dispatch, plan, stripeAccountName]);

  useEffect(() => {
    if (plan.length > 0 && Object.keys(analyticsParams).length > 0) {
      setLoadCheckout(true);
    }
  }, [plan, analyticsParams]);

  const onContinueClick = () => paymentCallback.current.onPurchaseClick(null);

  const continueButton = (displayStickyBtn, sticky, id) => (
    <AppButton
      onClick={onContinueClick}
      bottomPosition={'24px'}
      displayStickyBtn={displayStickyBtn}
      position={sticky}
      customStickyWidth={'100%'}
      customBeforeHeight={false}
      displayStubBtn={false}
      customId={`paywall-continue-button-${id}`}
      disabled={!isFormValid}
    >
      {t('continue')}
    </AppButton>
  );

  const FEATURES = [
    t('know_when_your_partner'),
    t('track_car'),
    t('get_sos_alert'),
    t('know_loved_get_hurt'),
    t('see_the_history_of_whereabouts'),
  ];

  return (
    <AppContainer
      customPadding={
        windowWidth >= 450
          ? '72px 24px 24px'
          : windowWidth <= 380
          ? '5px 24px 24px'
          : '30px 24px 24px'
      }
      customHeight={'auto'}
    >
      <Title>
        {`${t('hey')}`}{' '}
        <span>{`${decodeURIComponent(email).split('@')[0]}`}!</span>
      </Title>
      <Lalka>
        <TimerWrapper>
          <TimerText
            dangerouslySetInnerHTML={{
              __html: t('remarketing_plan_description'),
            }}
          />
          <TimerCount>
            {t('time_remaining')} {min}:{checkNumber(sec)}
          </TimerCount>
          <TimerText>{t('scroll_down')}</TimerText>
        </TimerWrapper>
        <FeatureTitle customMargin={'0 0 20px'}>
          {t('unlock_unique_options')}
        </FeatureTitle>
        <PlanWrapper>
          <div>{t('remarketing_plan_title')}</div>
          <div>{t('remarketing_plan_price')}</div>
        </PlanWrapper>
        <FeatureTitle>{t('what_you_get')}</FeatureTitle>
        <FeaturesList>{FEATURES.map(renderFeature)}</FeaturesList>
      </Lalka>
      <CheckoutWrapper>
        {loadCheckout && (
          <CheckoutScreen
            implemented={true}
            paymentCallback={paymentCallback}
            stripePromise={stripePromise}
          />
        )}
      </CheckoutWrapper>
      <PlansDescription
        continueButton={continueButton}
        showHint={t('remarketing_plan_hint')}
        remarketing
      />
    </AppContainer>
  );
};

export default withStripeInit(RemarketingPaywall);
