import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectActivePage } from '../store/ui';

const StyledBar = styled.div`
  max-width: 350px;
  width: 100%;
  min-height: 6px;
  display: flex;
  position: absolute;
  top: 20px;
  @media screen and (min-width: 450px) {
    position: static;
    margin-bottom: 10vh;
  }
`;

const StyledBarItem = styled.div`
  flex-grow: 1;
  border-radius: 14px;
  transition: background-color 300ms;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.btn : theme.colors.progress_inactive};
  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const SegmentedProgressBar = () => {
  const activePage = useSelector(selectActivePage);
  const [showBar, setShowBar] = useState(false);
  const pagesWithProgressBar = useMemo(
    () => [
      'crash-detection',
      'speed-control',
      'fall-detection',
      'place-alerts',
      'location-history',
      'sos-button',
      'real-time-location',
      'pair-wearables',
      'check-ins',
      'platform-support',
      'ar-search',
      'private-chat',
      'low-battery-notification',
      'choose-target',
      'choose-place',
    ],
    []
  );

  const activePageIndex = useMemo(
    () => pagesWithProgressBar.indexOf(activePage),
    [activePage, pagesWithProgressBar]
  );

  useEffect(() => {
    setShowBar(activePageIndex >= 0);
  }, [activePageIndex]);

  const renderProgress = (_, i) => {
    const isActive = i <= activePageIndex;

    return <StyledBarItem isActive={isActive} key={i} />;
  };

  if (!showBar) return null;

  return <StyledBar>{pagesWithProgressBar.map(renderProgress)}</StyledBar>;
};

export default SegmentedProgressBar;
