import React from 'react';
import { browserName } from 'react-device-detect';
import styled from 'styled-components';
import useWindowWidth from '../hooks/useWindowWidth';

const Button = styled.button`
  width: 100%;
  height: 44px;
  max-width: ${({ customWidth }) => customWidth || '327px'};
  background: ${({ customColor, theme }) => customColor || theme.colors.btn};
  color: ${({ customFontColor, theme }) =>
    customFontColor || theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_18};
  font-family: 'SF Pro Text';
  padding: 11px 20px;
  border: none;
  border-radius: 12px;
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 'initial'};
  ${({ customMargin }) => customMargin && `margin:${customMargin};`}
  ${({ customStyles }) => customStyles};

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;

    &:hover {
      cursor: default;
    }
  }
`;
const ButtonStub = styled.div`
  height: 44px;
  width: 100%;
  margin-bottom: 24px;
`;

const StickyWrapper = styled.div`
  --bottom-gap: 25px;

  position: ${({ position }) => position || 'fixed'};
  left: auto;
  bottom: ${({ bottomPosition }) => bottomPosition};
  width: ${({ customStickyWidth }) => customStickyWidth || '90%'};
  z-index: ${({ zIndex }) => zIndex || 'initial'};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 450px) {
    position: sticky;
    top: unset;
    align-items: ${({ alignStickyBtn }) => alignStickyBtn || 'center'};
  }

  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: ${({ customBeforeHeight, bottomPosition }) =>
      customBeforeHeight
        ? '0px'
        : `calc(100% + var(--bottom-gap) + ${bottomPosition})`};
    position: absolute;
    left: calc(-50vw + 50%);
    top: calc(var(--bottom-gap) * (-1));
    background: ${({ customBeforeBg }) =>
      customBeforeBg ||
      `linear-gradient(0deg,#fff 0%,#fff 75%,rgba(255,255,255,0.5) 85%,rgba(255,255,255,0.35) 95%,rgba(255,255,255,0) 100%)`};
    pointer-events: none;
  }
`;

const AppButton = ({
  onClick,
  children,
  disabled,
  customFontColor,
  customBeforeBg,
  customBeforeHeight = true,
  customColor,
  customHover,
  customMargin,
  customWidth,
  zIndex,
  position,
  displayStickyBtn = true,
  displayStubBtn = true,
  bottomPosition,
  customStickyWidth,
  stickyProps,
  alignStickyBtn,
  customId,
  customStyles,
}) => {
  const windowWidth = useWindowWidth();
  const btn = (
    <Button
      onClick={onClick}
      disabled={disabled}
      customFontColor={customFontColor}
      customBeforeBg={customBeforeBg}
      customBeforeHeight={customBeforeHeight}
      customColor={customColor}
      customHover={customHover}
      customMargin={customMargin}
      customWidth={customWidth}
      zIndex={zIndex}
      id={customId || 'continue-button'}
      customStyles={customStyles}
    >
      {children}
    </Button>
  );
  return displayStickyBtn && (bottomPosition || stickyProps) ? (
    <>
      <StickyWrapper
        position={position}
        bottomPosition={bottomPosition}
        zIndex={zIndex}
        customBeforeBg={customBeforeBg}
        customBeforeHeight={customBeforeHeight}
        customStickyWidth={customStickyWidth}
        alignStickyBtn={alignStickyBtn}
        {...stickyProps}
      >
        {btn}
      </StickyWrapper>
      {(((browserName === 'Facebook' || browserName === 'Instagram') &&
        displayStubBtn) ||
        (windowWidth <= 450 && displayStubBtn)) && <ButtonStub />}
    </>
  ) : (
    btn
  );
};

export default AppButton;
