import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TimerTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.font_20};
  line-height: ${({ theme }) => theme.lineHeights.lineHeight_24};
  color: ${({ theme }) => theme.colors.title};
  letter-spacing: -0.2px;
  margin-bottom: 8px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TimeItem = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.fontSizes.font_20};
  line-height: ${({ theme }) => theme.lineHeights.lineHeight_24};
  color: ${({ theme }) => theme.colors.btn};
  padding: 6px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.gradient_btn};
  position: relative;
  min-width: 50px;
  text-align: center;

  span {
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: ${({ theme }) => theme.fontSizes.font_12};
    line-height: ${({ theme }) => theme.lineHeights.lineHeight_14};
  }
`;

const TimeItemSep = styled.div`
  color: ${({ theme }) => theme.colors.btn};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.font_20};
  line-height: ${({ theme }) => theme.lineHeights.lineHeight_24};
  margin: 0 6px;
`;

export const checkNumber = (value) => {
  value = value.toString();
  if (value.length === 1) {
    return `0${value}`;
  }
  return value;
};

export const useTimerNumbers = () => {
  const [seconds, setSeconds] = useState(300);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        clearInterval(interval);
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  });

  const min = Math.floor(seconds / 60);
  const sec = seconds - min * 60;

  return { min, sec };
};

const AppTimerShort = () => {
  const { t } = useTranslation();
  const { min, sec } = useTimerNumbers();

  return (
    <TimerContainer>
      <TimerTitle>{t('limited_offer')}:</TimerTitle>
      <TimeWrapper>
        <TimeItem>
          {checkNumber(min)}
          <span>{t('minutes')}</span>
        </TimeItem>
        <TimeItemSep>:</TimeItemSep>
        <TimeItem>
          {checkNumber(sec)}
          <span>{t('seconds')}</span>
        </TimeItem>
      </TimeWrapper>
    </TimerContainer>
  );
};

export default AppTimerShort;
