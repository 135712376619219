import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWebp } from '../store/ui';
import useWindowWidth from './useWindowWidth';

export default function useImagesSrc() {
  const isWebp = useSelector(selectWebp);
  const windowWidth = useWindowWidth();

  const getInitImagesSources = () => {
    if (windowWidth > 376 && isWebp) {
      return {
        welcome: '../assets/desktop/webp/Welcome.webp',
        crashDetection: '../assets/desktop/webp/Crash_detection.webp',
        speedControl: '../assets/desktop/webp/Speed_control.webp',
        fallDetection: '../assets/desktop/webp/Fall_detection.webp',
        placeAlerts: '../assets/desktop/webp/Place_alerts.webp',
        locationHistory: '../assets/desktop/webp/Location_history.webp',
        sosButton: '../assets/desktop/webp/Sos_button.webp',
        realTimeLocation: '../assets/desktop/webp/Realtime_location.webp',
        pairWearables: '../assets/desktop/webp/Pair_with_wearables.webp',
        checkIns: '../assets/desktop/webp/Check-ins.webp',
        platformSupport: '../assets/desktop/webp/Platform_support.webp',
        arSearch: '../assets/desktop/webp/Ar_search.webp',
        privateChat: '../assets/desktop/webp/Private_chat.webp',
        lowBattery: '../assets/desktop/webp/Low_battery_notification.webp',
      };
    }
    if (windowWidth < 376 && isWebp) {
      return {
        welcome: '../assets/mobile/webp/Welcome.webp',
        crashDetection: '../assets/mobile/webp/Crash_detection.webp',
        speedControl: '../assets/mobile/webp/Speed_control.webp',
        fallDetection: '../assets/mobile/webp/Fall_detection.webp',
        placeAlerts: '../assets/mobile/webp/Place_alerts.webp',
        locationHistory: '../assets/mobile/webp/Location_history.webp',
        sosButton: '../assets/mobile/webp/Sos_button.webp',
        realTimeLocation: '../assets/mobile/webp/Realtime_location.webp',
        pairWearables: '../assets/mobile/webp/Pair_with_wearables.webp',
        checkIns: '../assets/mobile/webp/Check-ins.webp',
        platformSupport: '../assets/mobile/webp/Platform_support.webp',
        arSearch: '../assets/mobile/webp/Ar_search.webp',
        privateChat: '../assets/mobile/webp/Private_chat.webp',
        lowBattery: '../assets/mobile/webp/Low_battery_notification.webp',
      };
    }
    if (windowWidth > 376 && !isWebp) {
      return {
        welcome: '../assets/desktop/png/Welcome.png',
        crashDetection: '../assets/desktop/png/Crash_detection.png',
        speedControl: '../assets/desktop/png/Speed_control.png',
        fallDetection: '../assets/desktop/png/Fall_detection.png',
        placeAlerts: '../assets/desktop/png/Place_alerts.png',
        locationHistory: '../assets/desktop/png/Location_history.png',
        sosButton: '../assets/desktop/png/Sos_button.png',
        realTimeLocation: '../assets/desktop/png/Realtime_location.png',
        pairWearables: '../assets/desktop/png/Pair_with_wearables.png',
        checkIns: '../assets/desktop/png/Check-ins.png',
        platformSupport: '../assets/desktop/png/Platform_support.png',
        arSearch: '../assets/desktop/png/Ar_search.png',
        privateChat: '../assets/desktop/png/Private_chat.png',
        lowBattery: '../assets/desktop/png/Low_battery_notification.png',
      };
    }
    return {
      welcome: '../assets/mobile/png/Welcome.png',
      crashDetection: '../assets/mobile/png/Crash_detection.png',
      speedControl: '../assets/mobile/png/Speed_control.png',
      fallDetection: '../assets/mobile/png/Fall_detection.png',
      placeAlerts: '../assets/mobile/png/Place_alerts.png',
      locationHistory: '../assets/mobile/png/Location_history.png',
      sosButton: '../assets/mobile/png/Sos_button.png',
      realTimeLocation: '../assets/mobile/png/Realtime_location.png',
      pairWearables: '../assets/mobile/png/Pair_with_wearables.png',
      checkIns: '../assets/mobile/png/Check-ins.png',
      platformSupport: '../assets/mobile/png/Platform_support.png',
      arSearch: '../assets/mobile/png/Ar_search.png',
      privateChat: '../assets/mobile/png/Private_chat.png',
      lowBattery: '../assets/mobile/png/Low_battery_notification.png',
    };
  };

  const [imagesSources, setImagesSources] = useState(getInitImagesSources());

  useEffect(() => {
    const imagesSources = getInitImagesSources();
    setImagesSources(imagesSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    imagesSources,
  };
}
