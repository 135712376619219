import amplitude from 'amplitude-js';

export const initLockerStudio = (USER_ID = null, callback) => {
  amplitude
    .getInstance('lockerStudio')
    .init(
      process.env.REACT_APP_GISMART_KEY,
      USER_ID,
      { apiEndpoint: process.env.REACT_APP_LOCKER_STUDIO_DOMAIN },
      () => {
        callback('lockerStudio');
      }
    );
};

export const sendLockerStudioData = (EVENT_NAME, EVENT_PROPERTIES) => {
  const checkInit = setInterval(() => {
    if (window.sessionStorage.getItem('lockerStudioInit') === 'true') {
      amplitude
        .getInstance('lockerStudio')
        .logEvent(EVENT_NAME, EVENT_PROPERTIES);
      clearInterval(checkInit);
    }
  }, 500);
};
