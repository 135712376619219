import { createSlice } from '@reduxjs/toolkit';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    eventsData: {
      app_domain: '',
      utm_source: '',
      utm: {},
      os: '',
    },
    analyticsData: {},
  },
  reducers: {
    setEventData: (state, action) => {
      const { id, data } = action.payload;

      state.eventsData[id] = data;
    },
    setEventDataList: (state, action) => {
      Object.assign(state.eventsData, action.payload);
    },
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload;
    },
  },
});

export const { setEventData, setEventDataList, setAnalyticsData } =
  eventsSlice.actions;

export const selectEventsData = (state) => state.events.eventsData;
export const selectAnalyticsData = (state) => state.events.analyticsData;

export default eventsSlice.reducer;
