import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectWebp } from '../../../store/ui';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

export const FeatureTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  letter-spacing: -0.2px;
  text-align: center;
  margin: ${({ btnAfter }) => (btnAfter ? '0 0 12px 0' : '48px 0 12px 0')};
`;
export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${({ btnAfter }) => (btnAfter ? '0 0 24px 0' : '0 0 60px 0')};
`;

const StyledItem = styled.li`
  position: relative;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  text-align: ${textAlign};
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  align-items: center;

  svg {
    width: 9px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 5px;
    ${textAlign}: auto;
    text-align: initial;
  }

  > span {
    margin-${textAlign}: 13px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const MoneyBackWrapper = styled.div`
  max-width: 330px;
  min-width: 330px;
  background: #effffd;
  border: 3px solid #00baa5;
  border-radius: 12px;
  margin-top: ${({ btnAfter }) => (btnAfter ? '50px' : '0px')};
  padding: 0 19px 24px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -33px;
    left: calc(50% - 30px);
  }

  &:before {
    background-color: #fff;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 50%;
    border: inherit;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(-45deg);
  }

  &:after {
    width: 60px;
    height: 60px;
    background: url('../assets/plans/moneyBack.svg') center no-repeat;
  }
`;

const IosIcon = styled.div`
  width: 12px;
  height: 15px;
  background-image: url('../assets/plans/Apple_logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;
`;

const AndroidIcon = styled.div`
  width: 27px;
  height: 15px;
  background-image: url('../assets/plans/Android_logo.svg');
`;

const FeatureIcon = styled.div`
  width: 9px;
  height: 8px;
  background-image: url('../assets/plans/FeatureIcon.svg');
`;
const ForbesIcon = styled.div`
  width: 131px;
  height: 32px;
  background-image: url('../assets/plans/Forbes.svg');
`;

const MashableIcon = styled.div`
  width: 174px;
  height: 32px;
  background-image: url('../assets/plans/Mashable.svg');
`;

const TechCrunchIcon = styled.div`
  width: 311px;
  height: 45px;
  background-image: url('../assets/plans/TechCrunch.svg');
`;

const MoneyBackTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_31};
  color: ${(props) => props.theme.colors.money_back};
  margin: 40px 0 12px;
  text-align: center;
`;

const MoneyBackDescription = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.description};
  text-align: ${textAlign};
`;

export const StyledBtn = styled.button`
  background-color: transparent;
  text-decoration: underline;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.description};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  &:focus,
  &:active {
    outline: none;
  }
`;

const AboutUsWrapper = styled.div`
  max-width: 330px;
  margin: 48px 0 40px;
`;

const AboutUsTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 20px;
`;

const PressLogos = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 330px;

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const ReviewWrapper = styled.div`
  max-width: 330px;
`;

const ReviewTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 16px;
`;

const ReviewItem = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 24px;
  }
`;

const ReviewHead = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  position: relative;
`;

const AppIcon = styled.div`
  width: 11px;
  height: 14px;
  background-image: url('../assets/plans/small_logo.svg');
`;

const MarketLabel = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  ${textAlign}: auto;
`;

const Rate = styled.div`
  width: 53px;
  height: 10px;
  background-image: url('../assets/plans/stars.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-${textAlign}: 7px;
`;

const ReviewBody = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  align-items: center;
  margin-bottom: 8px;
`;

const ReviewerPhoto = styled.img`
  width: 30px;
  height: 30px;
`;

const ReviewerName = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_20};
  color: ${(props) => props.theme.colors.title};
  margin-${textAlign}: 8px;
`;

const ReviewText = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
  color: ${(props) => props.theme.colors.title};
  font-family: 'SF Pro Text';
  letter-spacing: -0.24px;
  text-align: ${textAlign};
`;

const TrialHint = styled.div`
  margin-top: 16px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_14};
  color: ${(props) => props.theme.colors.trial};
  text-align: center;
  transition: opacity 0.3s;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const renderFeature = (i, index) => (
  <StyledItem key={index}>
    <FeatureIcon />
    <span>{i}</span>
  </StyledItem>
);

const PlansDescription = ({
  continueButton,
  showHint,
  btnAfter,
  remarketing,
}) => {
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);
  const onLinkClick = (url) => window.open(url);
  const FEATURES = [
    t('know_when_family_come_or_go'),
    t('realtime_location_sharing'),
    t('find_loved_ones'),
    t('be_notified_in_case'),
    t('track_car'),
    t('know_loved_get_hurt'),
    t('history_of_whereabouts'),
    t('device_pairing'),
    t('notify_family'),
  ];
  const renderFeature = (i, index) => (
    <StyledItem key={index}>
      <FeatureIcon />
      <span>{i}</span>
    </StyledItem>
  );
  const REVIEWS = [
    {
      market: 'ios',
      reviewer: 'John Eydman',
      reviewerSource: isWebp
        ? '../assets/plans/reviewer1.webp'
        : '../assets/plans/reviewer1.png',
      text: t('review_1'),
    },
    {
      market: 'ios',
      reviewer: 'Chris Topper',
      reviewerSource: isWebp
        ? '../assets/plans/reviewer2.webp'
        : '../assets/plans/reviewer2.png',
      text: t('review_2'),
    },
    {
      market: 'android',
      reviewer: 'Jane Stevenson',
      reviewerSource: isWebp
        ? '../assets/plans/reviewer3.webp'
        : '../assets/plans/reviewer3.png',
      text: t('review_3'),
    },
  ];
  const renderReview = (r, i) => (
    <ReviewItem key={i}>
      <ReviewHead>
        <AppIcon />
        <Rate />
        <MarketLabel>
          {r.market === 'ios' ? <IosIcon /> : <AndroidIcon />}
        </MarketLabel>
      </ReviewHead>
      <ReviewBody>
        <ReviewerPhoto src={r.reviewerSource} alt={r.reviewer} />
        <ReviewerName>{r.reviewer}</ReviewerName>
      </ReviewBody>
      <ReviewText>{r.text}</ReviewText>
    </ReviewItem>
  );
  const trialHint = <TrialHint show={showHint}>{showHint}</TrialHint>;
  return (
    <>
      {!remarketing && (
        <>
          <FeatureTitle btnAfter={btnAfter}>{t('what_you_get')}</FeatureTitle>
          <FeaturesList btnAfter={btnAfter}>
            {FEATURES.map(renderFeature)}
          </FeaturesList>
        </>
      )}
      {btnAfter && continueButton(true, 'sticky', 1)}
      <MoneyBackWrapper btnAfter={btnAfter} supportsWebP={isWebp}>
        <MoneyBackTitle>{t('money_back')}</MoneyBackTitle>
        <MoneyBackDescription>
          {t('money_back_description')}{' '}
          <StyledBtn
            onClick={() => onLinkClick('https://geozilla.com/terms-of-use/')}
            id="link-to-terms"
          >
            {t('terms')}
          </StyledBtn>
        </MoneyBackDescription>
      </MoneyBackWrapper>
      <AboutUsWrapper>
        <AboutUsTitle>{t('about_us')}</AboutUsTitle>
        <PressLogos>
          <div>
            <ForbesIcon />
            <MashableIcon />
          </div>
          <TechCrunchIcon />
        </PressLogos>
      </AboutUsWrapper>
      <ReviewWrapper>
        <ReviewTitle>{t('customer_reviews')}</ReviewTitle>
        {REVIEWS.map(renderReview)}
      </ReviewWrapper>
      {continueButton(false, '', remarketing ? 1 : 2)}
      {trialHint}
    </>
  );
};

export default PlansDescription;
