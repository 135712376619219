import supportsWebP from 'supports-webp';

export const fetchWrapper = (url, { body, ...customConfig } = {}) => {
  const headers = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(url, config).then(async (response) => {
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    return Promise.reject(data);
  });
};

export const calcPercent = (partialValue, totalValue) =>
  (100 * partialValue) / totalValue;

export const appendLink = (href, type = 'prefetch') => {
  const link = document.createElement('link');
  link.setAttribute('rel', type);
  link.setAttribute('href', href);
  link.setAttribute('as', 'image');
  document.head.appendChild(link);
};

export const canUseWebp = async () => await supportsWebP;

export const TargetTrackImagesLinks = [
  '../assets/whom_track/child.png',
  '../assets/whom_track/child_active.png',
  '../assets/whom_track/partner.png',
  '../assets/whom_track/partner_active.png',
  '../assets/whom_track/parent.png',
  '../assets/whom_track/parent_active.png',
  '../assets/whom_track/pet.png',
  '../assets/whom_track/pet_active.png',
  '../assets/whom_track/car.png',
  '../assets/whom_track/car_active.png',
  '../assets/whom_track/other.png',
  '../assets/whom_track/other_active.png',
];

export const PlaceTrackImagesLinks = [
  '../assets/place_track/home.png',
  '../assets/place_track/home_active.png',
  '../assets/place_track/school.png',
  '../assets/place_track/school_active.png',
  '../assets/place_track/work.png',
  '../assets/place_track/work_active.png',
  '../assets/place_track/custom.png',
  '../assets/place_track/custom_active.png',
];

export const checkoutImagesLinks = [
  '../assets/checkout/visa.png',
  '../assets/checkout/mastercard.png',
  '../assets/checkout/amex.png',
  '../assets/checkout/discover.png',
  '../assets/checkout/secure.png',
  '../assets/checkout/AppStore.svg',
  '../assets/checkout/ArrowLeft.svg',
  '../assets/checkout/GooglePlay.svg',
  '../assets/checkout/GZ_Logo.svg',
  '../assets/checkout/Payment_declined.svg',
  '../assets/checkout/Paypal.svg',
  '../assets/checkout/store-stars.svg',
];

export const checkoutWebpImagesLinks = [
  '../assets/checkout/visa.webp',
  '../assets/checkout/mastercard.webp',
  '../assets/checkout/amex.webp',
  '../assets/checkout/discover.webp',
  '../assets/checkout/secure.png',
  '../assets/checkout/AppStore.svg',
  '../assets/checkout/ArrowLeft.svg',
  '../assets/checkout/GooglePlay.svg',
  '../assets/checkout/GZ_Logo.svg',
  '../assets/checkout/Payment_declined.svg',
  '../assets/checkout/Paypal.svg',
  '../assets/checkout/store-stars.svg',
];

export const PlansImagesLinks = [
  '../assets/plans/reviewer1.png',
  '../assets/plans/reviewer2.png',
  '../assets/plans/reviewer3.png',
  '../assets/plans/moneyBack.svg',
  '../assets/plans/small_logo.svg',
  '../assets/plans/stars.svg',
  '../assets/plans/Android_logo.svg',
  '../assets/plans/Apple_logo.svg',
  '../assets/plans/FeatureIcon.svg',
  '../assets/plans/WhiteIcon.svg',
  '../assets/plans/SelectedIcon.svg',
  '../assets/plans/Forbes.svg',
  '../assets/plans/Mashable.svg',
  '../assets/plans/TechCrunch.svg',
];

export const PlansWebpImagesLinks = [
  '../assets/plans/reviewer1.webp',
  '../assets/plans/reviewer2.webp',
  '../assets/plans/reviewer3.webp',
  '../assets/plans/moneyBack.svg',
  '../assets/plans/small_logo.svg',
  '../assets/plans/stars.svg',
  '../assets/plans/Android_logo.svg',
  '../assets/plans/Apple_logo.svg',
  '../assets/plans/FeatureIcon.svg',
  '../assets/plans/WhiteIcon.svg',
  '../assets/plans/SelectedIcon.svg',
  '../assets/plans/Forbes.svg',
  '../assets/plans/Mashable.svg',
  '../assets/plans/TechCrunch.svg',
];

export const AddPlaceImagesLinks = [
  '../assets/map_track/small_map.png',
  '../assets/map_track/pin.svg',
  '../assets/map_track/map_pin.svg',
  '../assets/map_track/star.svg',
  '../assets/map_track/location.svg',
];

export const AddPlaceWebpImagesLinks = [
  '../assets/map_track/small_map.webp',
  '../assets/map_track/pin.svg',
  '../assets/map_track/map_pin.svg',
  '../assets/map_track/star.svg',
  '../assets/map_track/location.svg',
];

export const ProfileImagesLinks = [
  '../assets/profile/CheckIcon.svg',
  '../assets/profile/EmptyIcon.svg',
];
