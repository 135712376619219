import { useTranslation } from 'react-i18next';
import * as S from './styled';
import { useLayoutEffect, useState } from 'react';
import { SuccessIcon } from '../Icons';
import AppButton from '../../../../components/AppButton';
import { sendAnalyticsEvents } from '../../../../analytics/common';
import { EVENTS } from '../../../../analytics/events';

export const SuccessPopup = ({ onSuccessClick, isVisible }) => {
  const { t } = useTranslation();
  const [initEventSent, setInitEventStatus] = useState(false);
  useLayoutEffect(() => {
    if (initEventSent) return;
    sendAnalyticsEvents(`${EVENTS.popupEvent}__screen__load`, {
      screen__load: 'success',
    });
    setInitEventStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEventSent]);

  return (
    <>
      <S.Overlay $isVisible={isVisible} />
      <S.SuccessPopupWrapper>
        <SuccessIcon />
        <S.SuccessText>{t('success_payment')}</S.SuccessText>
        <S.SuccessSubText>{t('payment_received')}</S.SuccessSubText>
        <AppButton
          customStyles={{ 'min-height': '56px' }}
          customMargin={'24px 0 0 0'}
          customId="success-popup-continue"
          onClick={onSuccessClick}
        >
          {t('continue')}
        </AppButton>
      </S.SuccessPopupWrapper>
    </>
  );
};
