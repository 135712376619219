import { useEffect } from 'react';

function usePreloadImages(images) {
  useEffect(() => {
    const preloadImages = async () => {
      try {
        const promises = images.map(
          (image) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = resolve;
              img.onerror = reject;
              img.src = image;
            })
        );

        await Promise.all(promises);
      } catch (error) {
        console.error('Failed to preload images:', error);
      }
    };

    preloadImages();
  }, [images]);
}

export { usePreloadImages };
