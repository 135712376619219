import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: ${({ customHeight }) => customHeight || '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ customJustify }) => customJustify || 'flex-start'};
  ${({ customBgColor }) => customBgColor && `background:${customBgColor};`}
  padding: ${({ customPadding }) => customPadding || '19px 24px 24px'};
  @media screen and (min-width: 450px) {
    justify-content: center;
  }
`;
const AppContainer = ({
  customJustify,
  customBgColor,
  customPadding,
  customHeight,
  children,
}) => (
  <Wrapper
    customJustify={customJustify}
    customBgColor={customBgColor}
    customPadding={customPadding}
    customHeight={customHeight}
  >
    {children}
  </Wrapper>
);

export default AppContainer;
