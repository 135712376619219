import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, isIPad13, osVersion } from 'react-device-detect';
import Loader from './components/AppLoader';
import GlobalStyle from './theme/globalStyles';
import theme from './theme/theme';
import './i18n';
import AppAlert from './components/AppAlert';
import { selectUserUuid, setUserToken } from './store/signup';
import { setWebp } from './store/ui';
import { appendLink, canUseWebp } from './utils/utils';
import useImagesSrc from './hooks/useImagesSrc';
import { useFontLoader } from './hooks/useFontLoader';
import { fontFaces } from './utils/fontFaces';
import Router from './router';
import FacebookPixel from 'react-facebook-pixel';
import { FB_PIXEL_ID } from './analytics/facebook';
import ReactGA from 'react-ga4';
import { REACT_APP_GOOGLE_TRACKING_ID } from './analytics/GA';
import useInlineScript from './hooks/useInlineScript';
import oneLinkSmartScript from './scripts/OneLink';

function App() {
  const dispatch = useDispatch();
  const userUuid = useSelector(selectUserUuid);
  const { imagesSources } = useImagesSrc();

  useFontLoader(fontFaces);

  useInlineScript(oneLinkSmartScript, 'AF_SMART_SCRIPT');

  useEffect(() => {
    const storageToken = window.sessionStorage?.getItem('access_token');

    if (storageToken) {
      dispatch(setUserToken(storageToken));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setWebp((isIOS && osVersion < 14) || isIPad13 ? false : canUseWebp())
    );
    appendLink(`${imagesSources.welcome}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!userUuid) return;
    FacebookPixel.init(FB_PIXEL_ID, { external_id: userUuid });
    FacebookPixel.pageView();
  }, [userUuid]);

  useEffect(() => {
    REACT_APP_GOOGLE_TRACKING_ID &&
      ReactGA.initialize(REACT_APP_GOOGLE_TRACKING_ID);
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Loader showMessage />
        <BrowserRouter>
          <Router dispatch={dispatch} />
        </BrowserRouter>
      </ThemeProvider>
      <AppAlert />
    </Suspense>
  );
}

export default App;
