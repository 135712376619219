import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './locales/ar.json';
import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import zh from './locales/zh.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: navigator.language || navigator.userLanguage,
  resources: {
    ar: { translation: ar },
    de: { translation: de },
    en: { translation: en },
    es: { translation: es },
    fr: { translation: fr },
    it: { translation: it },
    zh: { translation: zh },
  },
  interpolation: {
    escapeValue: false,
  },

  wait: true,
});

export default i18n;
