import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import AppContainer from '../../components/AppContainer';
import { selectEmail, selectSkipPaywallDisabled } from '../../store/signup';
import AppTimer from '../../components/AppTimer';
import {
  selectPlan,
  setPlan,
  selectLandingType,
  setDefaultPlanId,
  selectShowCheckout,
} from '../../store/plans';
import AppButton from '../../components/AppButton';
import PlansDescription from './components/PlansDescription';
import useWindowWidth from '../../hooks/useWindowWidth';
import { CrossBtnContainer, SelectedIcon, WhiteIcon } from './DefaultPlan';
import CrossBtn from './components/CrossBtn';

const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_40};
  color: ${(props) => props.theme.colors.title};
  margin: 0 0 8px 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 360px;
  width: 100%;
  > span {
    color: ${(props) => props.theme.colors.btn};
  }
`;

const Description = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  margin: 0 0 24px;
  text-align: center;
  max-width: 330px;
`;

const StyledList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 35px 0 24px 0;
  list-style: none;
  max-width: 330px;
`;
const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.gradient_plan : theme.colors.main};
  border: ${({ active, theme }) =>
    active
      ? `1px solid ${theme.colors.main}`
      : `1px solid ${theme.colors.borderColor}`};
  color: ${({ active, theme }) =>
    active ? theme.colors.main : theme.colors.description};
  border-radius: 16px;
  width: 100%;
  min-height: 74px;
  padding: 7px 0;
  position: relative;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledPeriod = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    margin-right: 14px;
    margin-left: 14px;
  }
  span {
    font-weight: ${({ active, theme }) =>
      active ? theme.fontWeights.medium : theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
`;

const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-right: 7px;
  padding: 8px 10px;
  background-color: ${(props) => props.theme.colors.main};
  color: ${({ active, theme }) =>
    active ? theme.colors.most_popular : theme.colors.inactivePrice};
  border-radius: 12px;
`;

const StyledOldValue = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_16};
  color: ${(props) => props.theme.colors.oldPrice};
  text-decoration: line-through;
`;

const StyledValue = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_22};
  letter-spacing: -0.2px;
`;

const StyledPerWeek = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_8};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_10};
`;

const BestLabel = styled.div`
  position: absolute;
  top: 0;
  left: 32%;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 7px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_14};
  color: ${(props) => props.theme.colors.most_popular};
  text-transform: uppercase;
  transform: translateY(-50%);
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  padding: 5px 16px;
  border: 1px solid ${({ theme }) => theme.colors.most_popular};
`;

const DiscountPlan = ({ handleClick }) => {
  const email = useSelector(selectEmail);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const windowWidth = useWindowWidth();
  const landingType = useSelector(selectLandingType);
  const isSkipPaywallDisabled = useSelector(selectSkipPaywallDisabled);
  const showCheckout = useSelector(selectShowCheckout);

  const DEFAULT_PLANS = useMemo(
    () => ({
      'new-discount': [
        {
          id: 'gz-1-month-intro7d-499-1996',
          title: '1',
          period: `${t('week_trial')}`,
          price: '$4.99*',
          oldPrice: '$9.99',
          label: t('special_for_you'),
        },
        {
          id: 'gz-1-month-1499',
          title: '1',
          period: t('month'),
          price: '$14.99',
          oldPrice: '$29.99',
        },
        {
          id: 'gz-3-month-2499',
          title: '3',
          period: t('months'),
          price: '$24.99',
          oldPrice: '$49.99',
        },
      ],
    }),
    [t]
  );

  const currentPlans = useMemo(
    () => DEFAULT_PLANS[landingType],
    [landingType, DEFAULT_PLANS]
  );

  const discountPrices = useMemo(
    () => ({
      'gz-1-month-intro7d-499-1996': { price: '4.99', perMonth: '19.96' },
    }),
    []
  );

  useEffect(() => {
    if (showCheckout) return;
    dispatch(setPlan(currentPlans[0].id));
    dispatch(setDefaultPlanId(currentPlans[0].id));
  }, [dispatch, currentPlans, showCheckout]);

  const onPlanClick = (id) => dispatch(setPlan(id));

  const renderOffer = (i, index) => {
    const isActive = plan === i.id;
    return (
      <StyledLi
        active={isActive}
        onClick={() => onPlanClick(i.id)}
        key={i.id}
        id={`plan-button-${index + 1}`}
      >
        <StyledPeriod active={isActive}>
          <div>{isActive ? <SelectedIcon /> : <WhiteIcon />}</div>
          <span>
            {i.title} {i.period}
          </span>
        </StyledPeriod>
        <StyledPrice active={i.label}>
          {i.oldPrice && <StyledOldValue>{i.oldPrice}</StyledOldValue>}
          <StyledValue>{i.price}</StyledValue>
          <StyledPerWeek>{i.week}</StyledPerWeek>
        </StyledPrice>
        {i.label && <BestLabel>{i.label}</BestLabel>}
      </StyledLi>
    );
  };

  const continueButton = (displayStickyBtn, sticky, id) => (
    <AppButton
      onClick={handleClick}
      bottomPosition={'24px'}
      displayStickyBtn={displayStickyBtn}
      position={sticky}
      customStickyWidth={'100%'}
      customBeforeHeight={false}
      displayStubBtn={false}
      customId={`paywall-continue-button-${id}`}
    >
      {t('continue')}
    </AppButton>
  );

  return (
    <AppContainer
      customPadding={
        windowWidth >= 450
          ? '72px 24px 24px'
          : windowWidth <= 380
          ? '5px 24px 24px'
          : '30px 24px 24px'
      }
      customHeight={'auto'}
    >
      <CrossBtnContainer>
        {!isSkipPaywallDisabled && <CrossBtn />}
      </CrossBtnContainer>
      <Title>
        {`${t('hey')}`}{' '}
        <span>{`${decodeURIComponent(email).split('@')[0]}`}!</span>
      </Title>
      <Description>{t('based')}</Description>
      <AppTimer />
      <StyledList>{currentPlans.map(renderOffer)}</StyledList>
      {continueButton(true, 'sticky', 1)}
      <PlansDescription
        continueButton={continueButton}
        showHint={
          plan.includes('intro7d') &&
          t('trial_hint', {
            price: discountPrices[plan].price,
            perMonth: discountPrices[plan].perMonth,
          })
        }
      />
    </AppContainer>
  );
};

export default DiscountPlan;
