import { useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import AppTitle from '../../components/AppTitle';
import { selectEventsData, setEventData } from '../../store/events';
import { selectTarget, setPlace } from '../../store/info';
import {
  ContentWrapper,
  StyledItem,
  StyledItemWrapper,
  StyledList,
} from './TargetTrack';
import { selectWebp } from '../../store/ui';
import {
  AddPlaceImagesLinks,
  AddPlaceWebpImagesLinks,
} from '../../utils/utils';
import { usePreloadImages } from '../../hooks/useImagePreloader';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const PlaceTrack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isWebp = useSelector(selectWebp);
  const target = useSelector(selectTarget);
  const eventsData = useSelector(selectEventsData);
  const [whichPlace, setWhichPlace] = useState(null);
  const [initEventSent, setInitEventStatus] = useState(false);
  const isDisabled = !!whichPlace;
  const skipMap = browserName === 'Facebook' || browserName === 'Instagram';
  const BUTTONS = ['home', 'work', 'school', 'custom'];
  usePreloadImages(isWebp ? AddPlaceWebpImagesLinks : AddPlaceImagesLinks);
  useEffect(() => {
    if (initEventSent) return;
    sendAnalyticsEvents(EVENTS.onboardingPlace, eventsData);
    setInitEventStatus(true);
  }, [eventsData, initEventSent]);

  const onContinueClick = () => {
    dispatch(setEventData({ id: 'Place', data: whichPlace }));
    sendAnalyticsEvents(EVENTS.onboardingPlaceSubmitted, {
      ...eventsData,
      Place: whichPlace,
    });
    dispatch(setPlace(whichPlace));
    setTimeout(() => navigate(`/${skipMap ? 'signup' : 'add-place'}`), 200);
  };

  const renderItem = (i, index) => {
    const isActive = i === whichPlace;
    return (
      <StyledItemWrapper active={isActive} key={index}>
        <StyledItem
          onClick={() => setWhichPlace(i)}
          active={isActive}
          key={index}
          id={`option-${index + 1}`}
        >
          <img
            src={
              isActive
                ? `../assets/place_track/${i}_active.png`
                : `../assets/place_track/${i}.png`
            }
            alt={t(i)}
          />
        </StyledItem>
        <span>{t(i)}</span>
      </StyledItemWrapper>
    );
  };

  return (
    <>
      <ContentWrapper>
        <AppTitle
          textAlign={textAlign}
          containerWidth={'327px'}
          title={`${t(`be_sure_${target}`)}`}
          marginContainer={'0px 0px 35px 0px'}
        />
        <StyledList>{BUTTONS.map(renderItem)}</StyledList>
      </ContentWrapper>
      <AppButton
        onClick={onContinueClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        disabled={!isDisabled}
        displayStubBtn={false}
      >
        {t('continue')}
      </AppButton>
    </>
  );
};

export default PlaceTrack;
