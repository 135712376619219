import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const isStage = process.env.REACT_APP_ENV === 'stage';

let defaultAnalytics = null;

export const initFirebase = () => {
  if (isStage) return;
  firebase.initializeApp(firebaseConfig);

  defaultAnalytics = firebase.analytics();
};

export const sendFirebaseEvent = (eventName, eventParams) => {
  if (isStage) return;
  if (defaultAnalytics) {
    return defaultAnalytics.logEvent(eventName, eventParams);
  }
};

export const setUserId = (userId) => {
  if (isStage) return;
  const id = userId.toString();

  if (defaultAnalytics) {
    defaultAnalytics.setUserId(id);
  }
};
