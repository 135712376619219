import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventsData, setEventDataList } from '../../../store/events';
import useDebounce from '../../../hooks/useDebounce';
import { sendAnalyticsEvents } from '../../../analytics/common';
import { EVENTS } from '../../../analytics/events';
import {
  selectShowPaymentError,
  setCardholderName,
  setFormValidity,
} from '../../../store/checkout';
import { setError } from '../../../store/error';
import { selectWebp } from '../../../store/ui';
import { cardIcons } from '../../../icons/CardIcons';
import { selectShowCheckout } from '../../../store/plans';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledWrapper = styled.div`
  width: 100%;
  height: 220px;
  max-width: 327px;
  border-radius: 20px;
  position: relative;
  z-index: 10;
  margin: 0;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledHeadWrapper = styled.div`
  max-width: 330px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledHeadTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_16};
  color: ${(props) => props.theme.colors.title};
`;

const StyledCard = styled.div`
  width: 100%;
  min-height: 186px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  background: ${(props) => props.theme.colors.main};
  z-index: 10;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 54px;
  background: ${(props) => props.theme.colors.main};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 0 12px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: space-between;
  width: 100%;

  & > div {
    width: 47%;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 22px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_22};
  color: ${(props) => props.theme.colors.description};
  padding: 16px 12px;
  background-color: transparent;
  border: none;
  padding-${textAlign}: 0px;
  text-align: ${textAlign};

  &::-webkit-credit-card-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    left: 0;
    ${textAlign}: auto;
  }

  &::placeholder {
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font_16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight_22};
    color: ${(props) => props.theme.colors.description};
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const StripeSecureIcon = styled.div`
  width: 74px;
  height: 25px;
  background-image: url('../assets/checkout/secure.png');
  background-size: 100%;
`;

const StyledBrandIcon = styled.span`
  position: absolute;
  right: 16px;
  left: 16px;
  ${textAlign}: auto;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stripe = ({ card, cvc, expiration, inputRef, implemented }) => {
  const { t } = useTranslation();
  const [cardBrand, setCardBrand] = useState('');
  const [name, setName] = useState('');
  const [isCvv, setIsCvv] = useState(null);
  const [isExp, setIsExp] = useState(null);
  const [isCard, setIsCard] = useState(null);
  const [err, setErr] = useState('');
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const eventsData = useSelector(selectEventsData);
  const isWebp = useSelector(selectWebp);
  const debouncedName = useDebounce(name, 500);
  const debouncedErr = useDebounce(err, 500);
  const debouncedValidity = useDebounce(isValid, 500);
  const showCheckout = useSelector(selectShowCheckout);
  const showError = useSelector(selectShowPaymentError);

  card.on('ready', () => {
    document.querySelectorAll('.__PrivateStripeElement-input').forEach((el) => {
      el.setAttribute('inputmode', 'numeric');
    });
    // card.focus();
  });

  useEffect(() => {
    if ((showCheckout || !showError) && !implemented) {
      card.focus();
    }
  }, [showCheckout, showError, card, implemented]);

  card.on('change', (event) => {
    if (event.brand && event.brand !== 'unknown' && event.brand !== cardBrand) {
      setCardBrand(event.brand);
    }
    if (event.empty) {
      setCardBrand('');
    }
    event.complete && expiration.focus();
    fieldEventHandler(event, setIsCard);
  });

  expiration.on('change', (event) => {
    event.complete && cvc.focus();
    fieldEventHandler(event, setIsExp);
  });

  cvc.on('change', (event) => {
    event.complete && inputRef.current?.focus();
    fieldEventHandler(event, setIsCvv);
  });

  useEffect(() => {
    card.mount('#card-number-element');
    expiration.mount('#card-expiry-element');
    cvc.mount('#card-cvc-element');
  }, [card, cvc, expiration]);

  useEffect(() => {
    if (cardBrand && eventsData && eventsData.card_brand !== cardBrand) {
      const localEventData = {
        card_brand: cardBrand,
        cardBrand: cardBrand,
      };
      dispatch(setEventDataList(localEventData));
      sendAnalyticsEvents(EVENTS.cardMethodChosen, {
        ...eventsData,
        ...localEventData,
      }); // Analytics event for starting typing the card number
    }
  }, [cardBrand, eventsData, dispatch]);

  const fieldEventHandler = (evt, setValidity) => {
    if (evt.error) {
      setErr(evt.error.message);
    }
    setValidity(evt.complete);
  };

  useEffect(() => {
    if (isCard && isCvv && isExp) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [isCard, isCvv, isExp, dispatch]);

  useEffect(() => {
    if (debouncedName) {
      dispatch(setCardholderName(debouncedName));
    }
  }, [debouncedName, dispatch]);

  useEffect(() => {
    if (debouncedErr) {
      dispatch(
        setError({ show: true, type: 'cardValidation', text: debouncedErr })
      );
    }
  }, [debouncedErr, dispatch]);

  useEffect(() => {
    dispatch(setFormValidity(debouncedValidity));
  }, [debouncedValidity, dispatch]);

  const renderCardIcon = (i) => (
    <img
      key={i.name}
      src={
        isWebp
          ? `../assets/checkout/${i.name}.webp`
          : `../assets/checkout/${i.name}.png`
      }
      width={i.width}
      height={i.height}
      alt={i.name}
    />
  );

  return (
    <StyledWrapper>
      <StyledHeadWrapper>
        <StyledHeadTitle>{t('checkout_with_card')}</StyledHeadTitle>
        <StripeSecureIcon />
      </StyledHeadWrapper>
      <StyledCard>
        <StyledFieldWrapper>
          <div id={'card-number-element'} />
          <StyledBrandIcon>
            {cardBrand &&
              cardIcons.map((i) => i.name === cardBrand && renderCardIcon(i))}
          </StyledBrandIcon>
        </StyledFieldWrapper>
        <StyledRow>
          <StyledFieldWrapper>
            <div id={'card-expiry-element'} />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <div id={'card-cvc-element'} />
          </StyledFieldWrapper>
        </StyledRow>
        <StyledFieldWrapper>
          <StyledInput
            ref={inputRef}
            onChange={(e) => setName(e.target.value)}
            type="text"
            autocomplete="cc-name"
            placeholder={t('name_on_card')}
            id="cardholder-name-element"
          />
        </StyledFieldWrapper>
      </StyledCard>
    </StyledWrapper>
  );
};

export default Stripe;
