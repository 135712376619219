import styled, { keyframes } from 'styled-components';

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 16px;
  max-width: 432px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme?.breakPoints.desktopMinWidth}px) {
    justify-content: center;
  }
`;

export const TitleWrapper = styled.div`
  padding: 0 0 16px 50px;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
`;

export const GeozillaIcon = styled.div`
  width: 38px;
  height: 48px;
  background: url(${'../assets/success/gz-logo.svg'}) center / contain no-repeat;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 6px;
`;

export const InstructionsTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
`;

export const InstructionsWrapper = styled.div`
  --counter-size: 48px;
  --pb: 20px;
  padding-bottom: var(--pb);
  margin-top: 20px;
  border-bottom: 1px solid #e9e9e9;
  counter-reset: my-sec-counter;
  position: relative;
  isolation: isolate;

  &:before {
    --gap: 13px;
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--counter-size) + var(--gap));
    left: calc(var(--counter-size) / 2);
    width: 2px;
    height: calc(100% - var(--counter-size) * 2 - var(--pb) - var(--gap) * 2);
    background: repeating-linear-gradient(
      to bottom,
      #00ceb5,
      #00ceb5 4px,
      #ffffff 4px,
      #ffffff 8px
    );
    z-index: -1;
  }
`;

export const ItemRow = styled.div`
  width: 100%;
  min-height: var(--counter-size);
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  padding-left: 64px;
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }

  span {
    font-weight: 700;
  }

  &:before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--counter-size);
    height: var(--counter-size);
    padding: 4px;
    border-radius: 8px;
    background: #e5f8f6;
    border: 1px solid #33ccb7;
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    color: #00a08b;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }
`;

export const Screenshot = styled.div`
  width: 100%;
  height: 82px;
  background: #ebfaf8;
  border: 1px solid #00a08b;
  border-radius: 6px;
  margin-top: 8px;
  padding: 8px;
`;

export const ScreenshotButton = styled.div`
  width: min(217px, 100%);
  height: 30px;
  border-radius: 70px;
  background: #00bfa5;
  margin: 0 auto;
  font-weight: 500;
  font-size: 11px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
`;

export const ScreenshotHighlight = styled.div`
  margin: 8px auto 0;
  font-size: 8px;
  line-height: 28px;
  text-align: center;
  color: #899497;
  min-width: 155px;
  height: 28px;
  background: url(${'../assets/success/highlight.png'}) center no-repeat;
  background-size: 100% 100%;
  width: fit-content;
  padding: 0 20px;
`;

export const CheersTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 22px;
`;

export const CheersText = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin: 6px 0 32px;

  @media (min-width: ${({ theme }) => theme?.breakPoints.tabletMinWidth}px) {
    margin-bottom: 24px;
  }
`;

export const DesktopQR = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme?.breakPoints.tabletMinWidth}px) {
    background: #ebfaf8;
    border: 1px solid #9aede2;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
`;

export const QRCode = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #fff;
  margin-right: 24px;
  position: relative;

  & > canvas {
    width: 100px;
    height: 100px;
  }
`;

export const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.svg`
  width: 40px;
  height: 40px;
  animation: 1.5s linear 0s infinite normal none running ${spin};
`;
