import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useDebounce from '../../hooks/useDebounce';
import { setEmail } from '../../store/signup';
import { setEventData } from '../../store/events';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Container = styled.div`
  width: 100%;
  max-width: 327px;
  margin-bottom: 24px;

  @media screen and (max-width: 450px) {
    margin-bottom: 8px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 45px;
  border: none;
  border: ${(props) =>
    props.error
      ? `1px solid ${props.theme.colors.error}`
      : `1px solid ${props.theme.colors.borderColor}`};
  border-radius: 8px;
  background: ${(props) => props.theme.colors.main};
  padding: 13px 15px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
  color: ${(props) => props.theme.colors.title};
  font-family: 'SF Pro Text';
  margin-bottom: 12px;
  outline: none;
  text-align: ${textAlign};

  &::placeholder {
    color: ${(props) => props.theme.colors.info};
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
  }

  &:focus,
  &:active {
    outline: none;
    border: ${(props) => `1px solid ${props.theme.colors.title}`};
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 8px;
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: space-between;
  min-height: 30px;

  @media screen and (max-width: 350px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const DomainBtn = styled.button`
  margin-right: 5px;
  box-sizing: border-box;
  width: fit-content;
  height: 28px;
  min-width: 90px;
  border: none;
  background: ${(props) => props.theme.colors.gradient_btn};
  border-radius: 8px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_16};
  color: ${(props) => props.theme.colors.domain};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

const EmailInput = () => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const debouncedEmail = useDebounce(value, 200);
  const DOMAINS = useMemo(() => ['gmail.com', 'yahoo.com', 'hotmail.com'], []);

  useEffect(() => {
    if (debouncedEmail) {
      dispatch(setEmail({ email: debouncedEmail, isValid }));
      dispatch(
        setEventData({
          id: 'user_email',
          data: debouncedEmail,
        })
      );
    }
  }, [debouncedEmail, dispatch, isValid]);

  useEffect(() => {
    const isEmailValid = EMAIL_REGEX.test(value);
    setIsValid(isEmailValid);

    if (isEmailValid) {
      const currentDomain = value.split('@')[1];
      setSelected(DOMAINS.indexOf(currentDomain) !== -1 ? currentDomain : null);
    }
  }, [value, DOMAINS]);

  const renderDomains = useCallback(() => {
    const onAddDomainClick = (domain) => {
      if (!value) return;
      setValue((prevState) => (prevState.split('@')[0] += domain));
      setSelected(domain);
    };

    const renderDomainItem = (item, i) => (
      <DomainBtn
        key={item}
        disabled={selected}
        onClick={() => onAddDomainClick(`@${item}`)}
        id={`domain-button-${i + 1}`}
      >
        @{item}
      </DomainBtn>
    );

    if (value && selected) {
      return DOMAINS.filter((i) => i === selected).map(renderDomainItem);
    }

    return DOMAINS.map(renderDomainItem);
  }, [value, selected, DOMAINS]);
  return (
    <Container>
      <Input
        type="email"
        error={value && !isValid}
        autoComplete="email"
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        placeholder={t('enter_your_mail')}
        id={'sign-up-input'}
      />
      <BtnContainer>{renderDomains()}</BtnContainer>
    </Container>
  );
};

export default EmailInput;
