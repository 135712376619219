import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import AppContainer from '../../components/AppContainer';
import {
  selectErrorText,
  setFormValidity,
  setShowPaymentError,
} from '../../store/checkout';
import { selectEventsData } from '../../store/events';
import { setAlternativeStripeDataAsMain } from '../../store/signup';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const PaymentDeclinedIcon = styled.div`
  width: 160px;
  height: 168px;
  background-image: url('../assets/checkout/Payment_declined.svg');
`;

const ImgWrapper = styled.div`
  margin-bottom: 15px;
`;
const Title = styled.h1`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
  text-align: center;
  margin: 0 0 4px;
`;

const ErrorText = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_17};
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  margin-bottom: 16px;
`;

const StyledList = styled.ul`
  padding-left: 30px;
  margin-bottom: 70px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  max-width: 330px;
  ${isArabic && 'direction: rtl;'}

  li {
    position: relative;
    text-align: ${textAlign};

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const ErrorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorText = useSelector(selectErrorText);
  const eventsData = useSelector(selectEventsData);
  const POINTS = ['your_card_balance', 'limits_on_card', 'contact_card_issue'];

  const renderCheckPoints = (point) => <li key={point}>{t(point)}</li>;

  const tryAgainClick = () => {
    dispatch(setAlternativeStripeDataAsMain());
    dispatch(setShowPaymentError({ show: false, text: '' }));
    dispatch(setFormValidity(null));
    sendAnalyticsEvents(EVENTS.onboardingDeclineTryAgain, eventsData);
    navigate('/checkout');
  };

  return (
    <AppContainer customJustify={'center'}>
      <ImgWrapper>
        <PaymentDeclinedIcon />
      </ImgWrapper>
      <Title>{t('payment_declined')}</Title>
      <ErrorText>{errorText}</ErrorText>
      <StyledList>{POINTS.map(renderCheckPoints)}</StyledList>
      <AppButton
        onClick={tryAgainClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        customId={'error-button'}
        displayStubBtn={false}
      >
        {t('try_again')}
      </AppButton>
    </AppContainer>
  );
};

export default ErrorScreen;
