export const PLANS = {
  'gz-1-month-1999': {
    name: 'gz-1-month-1999',
    price: 19.99,
    periodPrice: 19.99,
    disclaimer: 'monthly',
    description: 'one month',
    productId: '1 month - $19.99',
    duration: '1 month',
  },
  'gz-1-month-3999': {
    name: 'gz-1-month-3999',
    price: 39.99,
    periodPrice: 39.99,
    disclaimer: 'monthly',
    description: 'one month',
    productId: '1 month - $39.99',
    duration: '1 month',
  },
  'gz-3-month-5999': {
    name: 'gz-3-month-5999',
    price: 59.99,
    periodPrice: 59.99,
    disclaimer: 'quarterly',
    description: 'three months',
    productId: '3 months - $59.99',
    duration: '3 months',
  },
  'gz-6-month-panther': {
    name: 'gz-6-month-panther',
    price: 71.76,
    periodPrice: 71.76,
    disclaimer: 'semi_annually',
    description: 'six months',
    productId: '6 months - $71.76',
    duration: '6 months',
  },
  // remarketing plans
  'gz-6-month-3599': {
    name: 'gz-6-month-3599',
    price: 35.99,
    periodPrice: 35.99,
    description: 'six months',
    productId: '6 months - $35.99',
    duration: '6 months',
  },
  'gz-3-months-2999': {
    name: 'gz-3-months-2999',
    price: 29.99,
    periodPrice: 29.99,
    description: 'three months',
    productId: '3 months - $29.99',
    duration: '3 months',
  },
  'gz-1-month-intro7d-499-1996': {
    name: 'gz-1-month-intro7d-499-1996',
    price: 19.96,
    periodPrice: 4.99,
    description: 'one week trial',
    productId: '1 month - $19.96',
    duration: '1 month',
  },
  'gz-1-month-1499': {
    name: 'gz-1-month-1499',
    price: 14.99,
    periodPrice: 14.99,
    description: 'one month',
    productId: '1 month - $14.99',
    duration: '1 month',
  },
  'gz-3-month-2499': {
    name: 'gz-3-month-2499',
    price: 24.99,
    periodPrice: 24.99,
    description: 'three months',
    productId: '3 months - $24.99',
    duration: '3 months',
  },
  'gz-1-month-free3d-1499': {
    name: 'gz-1-month-free3d-1499',
    price: 14.99,
    periodPrice: 0,
    description: 'three days free trial',
    productId: '1 month - $14.99',
    duration: '1 month',
  },
  'gz-1-month-free3d-1996': {
    name: 'gz-1-month-free3d-1996',
    price: 19.96,
    periodPrice: 0,
    description: 'three days free trial',
    productId: '1 month - $19.96',
    duration: '1 month',
  },
  'gz-1-month-intro1m-999-2999': {
    name: 'gz-1-month-intro1m-999-2999',
    price: 29.99,
    periodPrice: 9.99,
    description: 'one month trial',
    productId: '1 month - $29.99',
    duration: '1 month',
  },
  'gz-1-month-intro14d-1.99-29.99': {
    name: 'gz-1-month-intro14d-1.99-29.99',
    price: 29.99,
    periodPrice: 1.99,
    description: 'two weeks trial',
    productId: '1 month - $29.99',
    duration: '1 month',
  },
};
