import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectWebp } from '../store/ui';

const StyledList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  list-style: none;
  padding: 0;
  max-width: 330px;
  @media screen and (max-width: 320px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const StyledLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 20px;
  }
  @media screen and (max-width: 320px) {
    :last-child {
      margin-right: 8px;
    }
    :nth-child(1),
    :nth-child(2) {
      margin-bottom: 8px;
    }
  }
`;

export const cardIcons = [
  { name: 'visa', width: '47px', height: '14px' },
  { name: 'mastercard', width: '45px', height: '28px' },
  { name: 'amex', width: '51px', height: '24px' },
  { name: 'discover', width: '49px', height: '10px' },
];

const CardIcons = () => {
  const isWebp = useSelector(selectWebp);
  return (
    <StyledList>
      {cardIcons.map((card) => (
        <StyledLi key={card.name}>
          <img
            src={
              isWebp
                ? `../assets/checkout/${card.name}.webp`
                : `../assets/checkout/${card.name}.png`
            }
            alt={card}
            width={card.width}
            height={card.height}
          />
        </StyledLi>
      ))}
    </StyledList>
  );
};

export default CardIcons;
