import { useEffect } from 'react';

export const useFontLoader = (fontFaces) => {
  useEffect(() => {
    const fontsToLoad = fontFaces.reduce((acc, { family, weights }) => {
      const fonts = weights.map(({ weight, src, options }) => {
        const font = new FontFace(family, src, options);
        document.fonts.add(font);
        return font.load();
      });
      return [...acc, ...fonts];
    }, []);

    Promise.all(fontsToLoad)
      .then(() => {
        // All fonts have been preloaded
      })
      .catch((error) => {
        // Failed to load fonts
      });
  }, [fontFaces]);
};
