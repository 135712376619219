import React from 'react';
import styled from 'styled-components';
import { cardBrandsIcons } from './Icons';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledCardFormWrapper = styled.div`
  width: 100%;
  height: 200px;
  max-width: 327px;
  border-radius: 20px;
  position: relative;
  z-index: 10;
  margin: 0;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledCardForm = styled.div`
  width: 100%;
  min-height: 186px;
  border-radius: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  z-index: 10;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 54px;
  background: #f9f9fa;
  border: 1px solid #d4dae0;
  border-radius: 12px;
  padding: 0 12px;
  overflow: hidden;

  & > div {
    height: 28px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    width: calc(50% - 10px);
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 28px;
  font-family: Open Sans, Segoe UI, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #3393ec;
  background-color: transparent;
  border: none;
  text-align: ${textAlign};

  &::-webkit-credit-card-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::placeholder {
    color: #adadad;
    letter-spacing: 1.2px;
    font-size: 18px;
    line-height: 22px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const StyledBrandIcon = styled.span`
  position: absolute;
  right: 12px;
  left: 12px;
  ${textAlign}: auto;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardForm = ({
  method,
  card,
  expiration,
  cvc,
  cardholder,
  cardBrand,
  setName,
  t,
}) => {
  const isStripe = method === 'stripe';

  return (
    <StyledCardFormWrapper>
      <StyledCardForm>
        <StyledFieldWrapper>
          <div id={'card-number-element'} ref={!isStripe ? card : null} />
          {cardBrand && (
            <StyledBrandIcon>{cardBrandsIcons[cardBrand]}</StyledBrandIcon>
          )}
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <div id={'card-expiry-element'} ref={!isStripe ? expiration : null} />
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <div id={'card-cvc-element'} ref={!isStripe ? cvc : null} />
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <StyledInput
            id={'cardholder-name-element'}
            ref={cardholder}
            onChange={(e) => setName(e.target.value)}
            type={'text'}
            autocomplete={'cc-name'}
            placeholder={t('name_on_card')}
          />
        </StyledFieldWrapper>
      </StyledCardForm>
    </StyledCardFormWrapper>
  );
};

export default CardForm;
