import styled, { useTheme } from 'styled-components';

const SVGWrapper = styled.div`
  position: relative;
`;

const SVGCircles = styled.svg`
  transform: rotate(-90deg);
`;

const SVGLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_40};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_52};
  text-align: center;
`;

const AppCircularProgress = (props) => {
  const theme = useTheme();
  const {
    size = 180,
    progress = 0,
    trackWidth = 10,
    trackColor = `${theme.colors.trackColor}`,
    indicatorWidth = 10,
    indicatorColor = `${theme.colors.btn}`,
    indicatorCap = `round`,
    labelColor = `${theme.colors.btn}`,
    spinnerMode = false,
    spinnerSpeed = 1,
  } = props;

  const center = size / 2;
  const radius =
    center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <SVGWrapper style={{ width: size, height: size }}>
        <SVGCircles style={{ width: size, height: size }}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </SVGCircles>

        <SVGLabel style={{ color: labelColor }}>
          {!spinnerMode && <span>{`${progress > 100 ? 100 : progress}%`}</span>}
        </SVGLabel>
      </SVGWrapper>
    </>
  );
};

export default AppCircularProgress;
